import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { Active } from "@dnd-kit/core";

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import { SortableItem } from "./SortableItem";
import { SortableOverlay } from "./SortableOverlay";

export type BaseItem = {
  id: string;
  value: string;
  description?: string;
  infoText?: string;
};

type SortableListProps = {
  items: BaseItem[];
  onChangeOrder: (items: BaseItem[]) => void;
  onRemove: (item: BaseItem | undefined) => void;
  onEdit?: (item: BaseItem, value: string) => void;
};

export const SortableList: FC<SortableListProps> = ({
  items: baseItems,
  onChangeOrder,
  onRemove,
  onEdit,
}) => {
  const [items, setItems] = useState(baseItems);
  const lastItemRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    setItems(baseItems);
    if (baseItems.length > items.length) {
      setTimeout(() => {
        lastItemRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [baseItems, items.length]);

  const [activeElement, setActiveElement] = useState<Active>();
  const activeItem = useMemo(
    () => items.find((item) => item.id === activeElement?.id),
    [activeElement, items],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleRemoveItem = useCallback(
    async (id: string) => {
      onRemove(items.find((item) => item.id === id));
      onChangeOrder(items.filter((item) => item.id !== id));
    },
    [items, onChangeOrder, onRemove],
  );

  const renderItem = (index: number, item: BaseItem, isLast: boolean) => {
    return (
      <li
        ref={isLast ? lastItemRef : null}
        key={item.id}
        className="w-full divide-y divide-secondary-100"
      >
        <SortableItem
          index={index}
          {...item}
          onRemove={() => handleRemoveItem(item.id)}
          onChange={onEdit ? (value) => onEdit(item, value) : undefined}
        />
      </li>
    );
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => setActiveElement(active)}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          const updated = arrayMove(items, activeIndex, overIndex);

          setItems(updated);

          // Wait until dragging animation has finished before saving
          setTimeout(() => onChangeOrder(updated), 100);
        }

        setActiveElement(undefined);
      }}
      onDragCancel={() => setActiveElement(undefined)}
    >
      <SortableContext items={items}>
        <ul
          className="flex w-full flex-col m-0 p-0 list-none items-start justify-start"
          data-testid="sortable-list"
        >
          {items.map((item, index) =>
            renderItem(index, item, index === items.length - 1),
          )}
        </ul>
      </SortableContext>

      <SortableOverlay>
        {activeItem && renderItem(-1, activeItem, false)}
      </SortableOverlay>
    </DndContext>
  );
};
