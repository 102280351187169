import React from "react";
import { useAnalytics } from "contexts/AnalyticsContext";
import { Icon } from "components/icon/Icon";
import { getButtonClasses } from "./Button.utils";
import { ButtonProps } from "./Button.types";

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      variant = "primary",
      size = "medium",
      label,
      disabled = false,
      icon,
      iconPosition = "left",
      className = "",
      spanClassName = "",
      analyticsId,
      analyticsData = {},
      onClick,
      testId,
      iconClassName,
      autoFocus,
      type = "button",
      children,
      ...props
    },
    ref,
  ) => {
    const analytics = useAnalytics();

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
      if (analyticsId && analytics?.gaEvent) {
        analytics.gaEvent({
          type: "click",
          payload: {
            analytics_id: analyticsId,
            ...analyticsData,
          },
        });
      }

      if (onClick) {
        return onClick(e);
      }
    };

    return (
      <button
        ref={ref}
        type={type === "submit" ? "submit" : "button"}
        id={id}
        className={getButtonClasses({
          variant,
          size,
          disabled,
          className,
        })}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        disabled={disabled}
        data-testid={testId}
        onClick={handleClick}
        {...props}
      >
        {iconPosition === "left" && icon && (
          <Icon name={icon} size={size!} iconClassName={iconClassName} />
        )}
        {label && (
          <span
            className={`relative flex flex-row ${spanClassName}`}
            style={{ margin: "0 auto" }}
          >
            {label}
          </span>
        )}
        {children}
        {iconPosition === "right" && icon && (
          <Icon name={icon} size={size!} iconClassName={iconClassName} />
        )}
      </button>
    );
  },
);
Button.displayName = "Button";
