import React from "react";
import { cn } from "utils";

export function Loader({ className }: { className?: string }) {
  return (
    <div className="relative">
      <div
        className={cn(
          "rounded-full border-5 border-primary-600 h-14 w-14 animate-loader opacity-0",
          className,
        )}
      />
      <div
        className={cn(
          "absolute inset-0 rounded-full border-5 border-primary-600 h-14 w-14 animate-loader opacity-0 delay-1000",
          className,
        )}
      />
    </div>
  );
}
