import { Input } from "components/ui/input";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "shared/constants";

export const HomeSearch = () => {
  const { t } = useTranslation(["home"]);
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToBooks = () => {
    navigate(RoutePath.Books, { state: { from: location.pathname } });
  };

  return (
    <Input
      onFocus={navigateToBooks}
      className="max-w-2xl h-13 shadow-outline-home hover:border-primary-500 rounded-2xl"
      placeholder={t("home:search.placeholder")}
    />
  );
};
