import { logError } from "shared/services/ErrorReporting";

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const openInNewTab = (url: string, searchParams: string = ""): void => {
  const anchor = document.createElement("a");
  if (searchParams) {
    anchor.href = `${url}?${searchParams}`;
  } else {
    anchor.href = url;
  }
  anchor.target = "_blank";
  anchor.rel = "noopener noreferrer";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const buildProjectUrl = (projectId: string): string => {
  return `/projects/${projectId}`;
};

export const generateAmazonLink = (isbn: string): string => {
  const baseUrl = "https://www.amazon.com/s?k=";
  return `${baseUrl}${isbn}`;
};

export const openTargetLinkInNewTab = async (url: string): Promise<void> => {
  try {
    const response = await fetch(url);
    const html = await response.text();

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const spanElement = doc.querySelector(
      'span[data-component-class="s-product-image"]',
    );
    if (spanElement) {
      const aTag = spanElement.firstElementChild as HTMLAnchorElement;
      if (aTag && aTag.href) {
        window.open(aTag.href, "_blank", "noopener,noreferrer");
      } else {
        logError("Target link not found within the span element.");
      }
    } else {
      logError(
        "Span element with data-component-class='s-product-image' not found.",
      );
    }
  } catch (error) {
    logError("Error fetching the URL:", { originalException: error });
  }
};

interface ButtonCopyArrayToClipboardProps {
  arrayToCopy: string[];
  delimiter?: string;
  onDone?: (content: string) => void;
}

export const copyToClipboard = async ({
  arrayToCopy,
  onDone,
  delimiter = "; ",
}: ButtonCopyArrayToClipboardProps) => {
  const textToCopy = arrayToCopy.join(delimiter);
  try {
    await navigator.clipboard.writeText(textToCopy);
    onDone?.(textToCopy);
  } catch (err) {
    logError(err);
  }
};

export function createArray(n: number) {
  return Array.from({ length: n }, (_, index) => index);
}

export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const downloadFile = (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = "";

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
