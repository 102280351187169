import {
  collection,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { db } from "integrations/firebase/firestore";

export enum TRENDS_TYPE {
  GOOGLE = "GOOGLE",
  AMAZON = "AMAZON",
}

export interface ITrend {
  uuid: string;
  term: string;
  region: string;
  rank: number;
  startDate: Timestamp;
  trendType: TRENDS_TYPE;
}

const trendConverter = {
  toFirestore(trend: ITrend) {
    return trend;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): ITrend {
    const documentData = snapshot.data();
    return documentData as ITrend;
  },
};

export const getTrendCollection = () =>
  collection(db, "trends").withConverter(trendConverter);

// export const getTrendsByCountry = (countryCode: string) => {
//   const queryTrendsGoogleOrAmazon = query(
//     getProductsByTrendCollection(),
//     where("organisation.id", "==", organisation?.id),
//     where("trend.trendType", "==", selectedTrendsType),
//     ...(selectedTrendsType === TRENDS_TYPE.GOOGLE
//       ? [where("trend.region", "==", selectedCountryCode)]
//       : []),
//     orderBy("trend.rank", "asc"),
//   );

//   const snapshot = await getDocs(queryTrendsGoogleOrAmazon);
//   const loadedTrends = snapshot.docs.map((document) => ({
//     ...document.data(),
//     id: document.id,
//   })) as IProductsByTrend[];
// };
