import { flexRender } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { IProject } from "integrations/firebase/collections";
import { useProjects } from "pages/Projects/contexts/ProjectsContext";
import { useTable } from "hooks/useTable";
import { DataTablePagination } from "components/ui/data-table-pagination";
import { useEffect } from "react";
import { columns } from "./columns";
import { PreHeader } from "./PreHeader";
import { ProjectsTableBody } from "./ProjectsTableBody";
import { CompactPreHeader } from "./CompactPreHeader";

interface ProjectsTableProps {
  onRowClicked: (project: IProject) => void;
  variant?: "default" | "compact";
  myProjectsOnly?: boolean;
}

const PAGE_SIZE = 10;
const emptyArray: IProject[] = [];
export const ProjectsTable = ({
  onRowClicked,
  variant = "default",
  myProjectsOnly = false,
}: ProjectsTableProps) => {
  const { projects, isLoadingRead, errorRead } = useProjects();
  const {
    goBackPage,
    goNextPage,
    currentPage,
    goToPage,
    showOnlyMyProjects,
    setShowOnlyMyProjects,
  } = useProjects();

  useEffect(() => {
    if (myProjectsOnly && !showOnlyMyProjects) {
      setShowOnlyMyProjects(true);
    }
  }, [variant, showOnlyMyProjects, setShowOnlyMyProjects, myProjectsOnly]);

  const { t } = useTranslation(["components"]);
  const displayProjects =
    variant === "compact"
      ? projects.slice(0, 3)
      : projects.slice(
          currentPage * PAGE_SIZE,
          currentPage * PAGE_SIZE + PAGE_SIZE,
        );

  const { table } = useTable({
    columns: columns(t),
    data: displayProjects || emptyArray,
    totalRecords: projects.length || 0,
    paginationState: { pagination: { pageIndex: currentPage, pageSize: 10 } },
  });

  return (
    <div className="space-y-4 text-left">
      {variant === "compact" ? <CompactPreHeader /> : <PreHeader />}
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <ProjectsTableBody
            isLoadingRead={isLoadingRead}
            errorRead={errorRead}
            projects={projects}
            table={table}
            columns={columns(t)}
            onRowClicked={onRowClicked}
          />
        </TableBody>
      </Table>
      {variant === "compact" ? null : (
        <DataTablePagination
          onNextPage={goNextPage}
          onPreviousPage={goBackPage}
          onSetPage={goToPage}
          table={table}
        />
      )}
    </div>
  );
};
