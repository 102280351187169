import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";
import { Button } from "../button";

const sizes = [10, 20, 30, 40, 50] as const;

export const DataTablePaginationPageSize = ({
  label,
  onSelect,
  size,
}: {
  label: string;
  onSelect: (size: number) => void;
  size: number;
}) => {
  return (
    <div className="text-secondary-600 flex items-center">
      <div className="mr-2">{label}</div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="tertiary"
            label={String(size)}
            icon="chevron-down"
            iconPosition="right"
            aria-label="Page size"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-[5rem]">
          {sizes.map((value) => (
            <DropdownMenuItem onClick={() => onSelect(value)} key={value}>
              {value}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
