import { Client } from "@hey-api/client-fetch";
import { OrganisationsApi } from "./organisations";

export class AccountApi {
  organisations: OrganisationsApi;

  constructor(private client: Client) {
    this.organisations = new OrganisationsApi(this.client);
  }
}
