import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { getOrganisationsCollection } from "integrations/firebase/collections";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  doc,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { FirestoreOrganisation } from "__generated__/models";
import { db } from "../integrations/firebase/firestore";

const collection = getOrganisationsCollection();

interface IOrganisationsContext {
  organisations: FirestoreOrganisation[];
  loading: boolean;

  addOrganisation(
    organisation: Pick<FirestoreOrganisation, "name">,
  ): Promise<void>;
  updateOrganisationName(id: string, name: string): Promise<void>;
}

export const OrganisationsContext = createContext<IOrganisationsContext>(
  undefined as never,
);

export const OrganisationsProvider = ({ children }: PropsWithChildren) => {
  const [organisations = [], loading] = useCollectionData(
    query(collection, orderBy("name")),
  );

  const addOrganisation = useCallback(
    async (organisation: Pick<FirestoreOrganisation, "name">) => {
      const id = uuid();
      const reference = doc(getOrganisationsCollection(), id);

      await runTransaction(db, async (transaction) => {
        const existing = await transaction.get(reference);

        if (existing.exists()) {
          throw new Error("Unable to add organisation with existing ID");
        }

        transaction.set(reference, {
          ...organisation,
          id,
          createdAt: serverTimestamp(),
        });
      });
    },
    [],
  );

  const updateOrganisationName = useCallback(
    (id: string, name: FirestoreOrganisation["name"]) =>
      updateDoc(doc(getOrganisationsCollection(), id), "name", name),
    [],
  );

  const value = useMemo(
    () => ({
      organisations,
      addOrganisation,
      loading,
      updateOrganisationName,
    }),
    [organisations, addOrganisation, loading, updateOrganisationName],
  );

  return (
    <OrganisationsContext.Provider value={value}>
      {children}
    </OrganisationsContext.Provider>
  );
};

export const useOrganisations = () => {
  const context = useContext(OrganisationsContext);

  if (!context) {
    throw new Error(
      "useOrganisations must be used within a OrganisationsProvider",
    );
  }

  return context;
};
