import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MultipleSelectChip } from "./MultipleSelectChip";
import { IMultipleSelectChipValue } from "./types";
import { createSchema } from "./utils/multiISBNSchema";

export type { IMultipleSelectChipValue };

interface IsbnMultipleSelectChipProps {
  values?: string[];
  onValueChange: (values: string[]) => void;
  className?: string;
  chipClassName?: string;
  chipInputClassName?: string;
  showErrors?: boolean;
}

export const IsbnMultipleSelectChip = ({
  values = [],
  onValueChange,
  className,
  chipClassName,
  chipInputClassName,
  showErrors = false,
}: IsbnMultipleSelectChipProps) => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      createSchema(
        t("components:IsbnMultipleSelectChip.lengthError"),
        t("components:IsbnMultipleSelectChip.uniqueError"),
      ),
    [t],
  );

  return (
    <MultipleSelectChip
      values={values}
      onValueChange={onValueChange}
      placeholder={t("components:IsbnMultipleSelectChip.placeholder")}
      className={className}
      chipClassName={chipClassName}
      chipInputClassName={chipInputClassName}
      showErrors={showErrors}
      schema={schema}
    />
  );
};
