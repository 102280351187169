import { SearchQueryRequest } from "__generated__/api";
import entries from "lodash/entries";
import {
  SearchFilterComparisonOperator,
  SearchQuery,
  SearchQueryFilters,
  SearchQueryFilterSchema,
  SearchQuerySchema,
  SearchType,
} from "./types";

export function parseParamFilters(filters: string[]): SearchQuery["filters"] {
  const parsed: SearchQueryFilters = {};

  if (!filters.length) {
    return parsed;
  }

  filters.forEach((filter) => {
    const [query, value] = filter.split(":");
    const [variable, operator] = query.split(".");

    if (!variable || !operator || !value) {
      return;
    }

    parsed[variable as keyof SearchQueryFilters] =
      SearchQueryFilterSchema.parse({
        value,
        comparisonOperator: operator as SearchFilterComparisonOperator,
      });
  });

  return parsed;
}

export function paramsToSearchQuery(params: URLSearchParams): SearchQuery {
  const filters = parseParamFilters(params.getAll("filter"));

  const query = params.get("q");
  const type = params.get("type");
  const page = params.get("page");
  const pageSize = params.get("pageSize");

  return SearchQuerySchema.parse({
    type: type ?? SearchType.QUICK,
    query: query ?? "",
    filters,
    page,
    pageSize,
  });
}

export const DEFAULT_PARAMS: SearchQueryRequest = {
  model: "products",
  q: "",
  query_by: "title,author,identifier",
  sort_by: "publishedAt:desc",
  filter_by: "",
  page: 1,
  per_page: 10,
  prefix: "false",
} as const;

export function parseComparisonOperator(
  comparisonOperator: SearchFilterComparisonOperator,
) {
  switch (comparisonOperator) {
    case SearchFilterComparisonOperator.EQUALS:
      return "=";
    case SearchFilterComparisonOperator.LESS:
      return "<";
    case SearchFilterComparisonOperator.GREATER:
      return ">";
    default:
      throw new Error("Invalid comparison operator");
  }
}

export function searchQueryFiltersToFilter(filters?: SearchQueryFilters) {
  const conditions: string[] = [];

  if (!filters) {
    return "";
  }

  entries(filters).forEach(([variable, filter]) => {
    if (!filter) {
      return;
    }

    const operator = parseComparisonOperator(filter.comparisonOperator);

    conditions.push(`${variable}:${operator}${filter.value}`);
  });

  return conditions.join(" && ");
}

export function searchQueryToSearchRequest(
  query: Partial<SearchQuery>,
): SearchQueryRequest {
  const baseRequest: SearchQueryRequest = {
    ...DEFAULT_PARAMS,
    q: query.query || "",
    page: query.page || 1,
    per_page: query.pageSize || 10,
    filter_by: searchQueryFiltersToFilter(query.filters),
  };

  switch (query.type) {
    case SearchType.SEMANTIC: {
      return {
        ...baseRequest,
        q: query.query || "*",
        page: 0,
        query_by: "summaryEmbedding",
        sort_by: "_vector_distance:asc",
      };
    }
    case SearchType.ISBN: {
      return {
        ...baseRequest,
        prefix: undefined,
        q: "",
        filter_by: query.query ? `identifier: [${query.query}]` : "",
      };
    }
    default: {
      return baseRequest;
    }
  }
}

export const vectorDistanceToPercentage = (distance: number) => {
  // typesense returns a number between 0 and 2, we convert it to a percentage
  const percentage = 100 - distance * 50;

  // First clamp the input to 0-100
  const clampedPercentage = Math.min(100, Math.max(0, percentage));

  // Scale the percentage so that 60% = 0% and 80% = 100%
  const scaledPercentage = Math.round(((clampedPercentage - 60) / 20) * 100);

  // Clamp the final result to 0-100 range
  return Math.min(100, Math.max(0, scaledPercentage));
};
