import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import { GenerationStatus, IProduct } from "integrations/firebase/collections";
import { Modal } from "components/modal/Modal";
import { GENERATE_METADATA_BOOKS_LIMIT } from "../../../shared/constants";
import { BulkGenerateOption } from "./BulkGenerateOption";

export function BulkGenerateButton({
  products,
  generateKeywords,
  generateDescriptions,
}: {
  products: IProduct[];
  generateKeywords: (products: IProduct[]) => void;
  generateDescriptions: (products: IProduct[]) => void;
}) {
  const { t } = useTranslation(["projectDetails"]);

  const [
    showDescriptionsGenerationWarning,
    setShowDescriptionsGenerationWarning,
  ] = useState(false);

  const [showKeywordsGenerationWarning, setShowKeywordsGenerationWarning] =
    useState(false);

  const handleGenerateKeywordsClick = useCallback(
    async (skipWarning = false) => {
      const hasGeneratedKeywords = products.some(
        (p) =>
          p.generated?.keywords &&
          p.generated?.keywords?.status !== GenerationStatus.IMPORTED,
      );

      if (hasGeneratedKeywords && !skipWarning) {
        setShowKeywordsGenerationWarning(true);
        return;
      }

      setShowKeywordsGenerationWarning(false);

      await generateKeywords(products);
    },
    [generateKeywords, products],
  );

  const handleGenerateDescriptionsClick = useCallback(
    async (skipWarning = false) => {
      const hasGeneratedDescriptions = products.some(
        (p) =>
          p.generated?.description &&
          p.generated?.description?.status !== GenerationStatus.IMPORTED,
      );

      if (hasGeneratedDescriptions && !skipWarning) {
        setShowDescriptionsGenerationWarning(true);
        return;
      }

      setShowDescriptionsGenerationWarning(false);

      await generateDescriptions(products);
    },
    [generateDescriptions, products],
  );

  const { generateAllEnabled, isGenerating } = useMemo(() => {
    if (!products?.length) {
      return { generateAllEnabled: false, isGenerating: false };
    }

    const hasRequested = products.some(
      (p) =>
        p.generated?.description?.status === "REQUESTED" ||
        p.generated?.keywords?.status === "REQUESTED",
    );

    const hasErrors = products.some((p) => p.nonExistent);

    if (hasErrors) {
      return { generateAllEnabled: false, isGenerating: hasRequested };
    }

    return { generateAllEnabled: !hasRequested, isGenerating: hasRequested };
  }, [products]);

  const disabled =
    isGenerating ||
    !generateAllEnabled ||
    products.length > GENERATE_METADATA_BOOKS_LIMIT;

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger
          disabled={disabled}
          className="disabled:text-secondary-400 text-secondary-700 group"
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="rounded-lg border flex-1 flex group-aria-expanded:border-secondary-600 cursor-pointer transition-colors">
                <div className="whitespace-nowrap font-normal flex items-center px-5 text-m">
                  {isGenerating
                    ? t("projectDetails:buttons.generateAll.generating")
                    : t("projectDetails:buttons.generateAll.metadata")}
                </div>

                <div className="transition-colors w-11 h-full border-l aspect-square flex items-center justify-center group-aria-expanded:border-l-secondary-600">
                  <ChevronDown className="w-5 h-5 transition-transform group-aria-expanded:rotate-180" />
                </div>
              </div>
            </TooltipTrigger>

            <TooltipContent
              data-testid="generate-all-tooltip"
              side="bottom"
              className="font-light text-center shadow-tooltip text-neutral-600 whitespace-pre px-5 z-10 border-none leading-relaxed"
              sideOffset={6}
            >
              <TooltipArrow
                className="fill-white drop-shadow-sm"
                width={12}
                height={6}
              />
              {products.length === 0
                ? t("projectDetails:buttons.generate.disabledNoBooks", {
                    count: GENERATE_METADATA_BOOKS_LIMIT,
                  })
                : null}
              {products.length > GENERATE_METADATA_BOOKS_LIMIT
                ? t("projectDetails:buttons.generate.disabledLimitBooks", {
                    count: GENERATE_METADATA_BOOKS_LIMIT,
                  })
                : null}
              {!disabled
                ? t("projectDetails:buttons.generateAll.tooltips.enabled")
                : null}
            </TooltipContent>
          </Tooltip>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          side="bottom"
          align="center"
          className="popover-content-width-full"
        >
          <BulkGenerateOption
            onClick={() => handleGenerateKeywordsClick(false)}
            label={t("projectDetails:buttons.generateAll.items.keywords")}
          />
          <BulkGenerateOption
            onClick={() => handleGenerateDescriptionsClick(false)}
            label={t("projectDetails:buttons.generateAll.items.descriptions")}
          />
        </DropdownMenuContent>
      </DropdownMenu>

      <Modal
        id="overwrite-description"
        variant="warning"
        title={t("projectDetails:modals.overwriteDescriptions.title")}
        description={t(
          "projectDetails:modals.overwriteDescriptions.description",
        )}
        confirmLabel={t(
          "projectDetails:modals.overwriteDescriptions.actions.confirm",
        )}
        confirmIconName="loader"
        cancelLabel={t(
          "projectDetails:modals.overwriteDescriptions.actions.cancel",
        )}
        open={showDescriptionsGenerationWarning}
        onOpenChange={() => setShowDescriptionsGenerationWarning(false)}
        onConfirm={() => handleGenerateDescriptionsClick(true)}
      />

      <Modal
        id="overwrite-keywords"
        variant="warning"
        title={t("projectDetails:modals.overwriteKeywords.title")}
        description={t("projectDetails:modals.overwriteKeywords.description")}
        confirmLabel={t(
          "projectDetails:modals.overwriteKeywords.actions.confirm",
        )}
        confirmIconName="loader"
        cancelLabel={t(
          "projectDetails:modals.overwriteKeywords.actions.cancel",
        )}
        open={showKeywordsGenerationWarning}
        onOpenChange={() => setShowKeywordsGenerationWarning(false)}
        onConfirm={() => handleGenerateKeywordsClick(true)}
      />
    </>
  );
}
