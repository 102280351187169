import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { useAuth } from "contexts/AuthContext";
import { Button } from "../button/Button";

export const SignoutModal = forwardRef(function SignoutModal() {
  const { t } = useTranslation(["general", "components"]);
  const { signOut } = useAuth();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="w-full p-2 text-left items-center text-secondary-600 hover:bg-gray-100 flex flex-row">
          <span>{t("components:header.logOut")}</span>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="space-y-1 py-2">
          <DialogTitle>{t("components:header.logOutMessage.one")}</DialogTitle>
          <DialogDescription>
            {t("components:header.logOutMessage.two")}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              variant="link"
              label={t("components:header.buttons.goBack")}
            />
          </DialogClose>
          <DialogClose asChild>
            <Button
              onClick={signOut}
              variant="primary"
              className="px-6"
              label={t("components:header.buttons.logOut")}
            />
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
});
