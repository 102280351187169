/* eslint-disable no-void */
import {
  ClipboardEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Button } from "components/button/Button";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "utils";
import { ZodSchema } from "zod";
import entries from "lodash/entries";
import groupBy from "lodash/groupBy";
import { Chip } from "./Chip";

type Schema = ZodSchema<{ values: Array<{ value: string }> }>;

export interface IMultipleSelectChipProps {
  values: string[];
  placeholder?: string;
  className?: string;
  chipClassName?: string;
  chipInputClassName?: string;
  showErrors?: boolean;
  schema?: Schema;

  onValueChange(values: string[]): void;
}

export const MultipleSelectChip: FC<IMultipleSelectChipProps> = ({
  onValueChange,
  values,
  placeholder,
  className = "",
  chipClassName = "",
  chipInputClassName = "",
  schema,
  showErrors,
}) => {
  const {
    watch,
    register,
    control,
    setFocus,
    formState,
    getValues,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      values: values.map((value) => ({ value })),
    },
    resolver: schema ? zodResolver(schema) : undefined,
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const current = watch("values");

  const array = useFieldArray({
    name: "values",
    control,
  });

  const fields = current.map((value, index) => ({
    ...array.fields[index],
    ...value,
  }));

  useEffect(() => {
    void trigger();
  }, [trigger, current]);

  useEffect(() => {
    // Update values if they change from outside
    setValue(
      "values",
      values.map((v) => ({ value: v })),
    );
  }, [values, setValue]);

  const [active, setActive] = useState<number | null>(null);

  const add = () => {
    array.append({ value: "" }, { shouldFocus: true });
  };

  const onSubmit = useCallback(() => {
    // Trigger to activate validation
    void trigger();
    return onValueChange(getValues().values.map((v) => v.value));
  }, [getValues, onValueChange, trigger]);

  const onKeyDown = async (
    event: KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { code } = event;

    const value = current[index];

    if (["Delete", "Backspace"].includes(code) && !value?.value) {
      event.preventDefault();
      array.remove(index);
      setFocus(`values.${Math.max(0, index - 1)}.value`);
      return;
    }

    if (event.code === "Enter") {
      event.stopPropagation();
      onSubmit();
      return;
    }

    const addNew = ["Space", "Semicolon", "Comma"].includes(code);

    if (!addNew) {
      return;
    }

    event.preventDefault();

    if (!value?.value) {
      return;
    }

    add();
  };

  const onRemove = async (index: number) => {
    array.remove(index);

    onSubmit();

    setActive(null);
  };

  const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const text = event.clipboardData.getData("text");
    const stripped = text
      .split(/[ ,;\n]+/)
      .map((x) => x.replaceAll(/\s+/g, ""))
      .filter((x) => x !== "");

    if (!stripped.length) {
      return;
    }

    setActive(null);

    array.append(
      stripped.map((value) => ({ value })),
      {
        shouldFocus: false,
      },
    );
  };

  useEffect(() => {
    if (active !== null) {
      return;
    }

    onSubmit();
  }, [active, onSubmit]);

  const onBlur = () => {
    setActive(null);
  };

  const onFocus = (index: number) => {
    setActive(index);
  };

  const errors = useMemo(() => {
    if (!showErrors) {
      return null;
    }

    const mapped = fields.map(({ value }, index) => {
      const error = formState.errors?.values?.[index]?.value;

      return {
        value,
        error,
      };
    });

    const errored = mapped.filter(({ error }) => error);

    const grouped = groupBy(errored, "error.message");

    return entries(grouped);
  }, [showErrors, fields, formState]);

  return (
    <div className="w-full h-auto" data-testid="multiple-select-chip">
      <div className={cn("flex flex-wrap", className)}>
        {fields.map(({ id, value }, index) => {
          const error = formState.errors?.values?.[index]?.value;

          return (
            <Chip
              key={id}
              title={value}
              {...register(`values.${index}.value`)}
              error={error?.message}
              onFocus={() => onFocus(index)}
              onRemove={() => onRemove(index)}
              onBlur={() => onBlur()}
              onKeyDown={(event) => onKeyDown(event, index)}
              onPaste={onPaste}
              className={chipClassName}
              inputClassName={chipInputClassName}
            />
          );
        })}

        <Button
          variant="ghost"
          onClick={add}
          className="whitespace-nowrap w-auto"
        >
          {placeholder}
        </Button>
      </div>

      {Boolean(errors?.length) && (
        <div className="mt-5">
          <b>Errors</b> <br />
          {errors?.map(([error, errored]) => (
            <div key={error}>
              <span>{error} </span>
              <small>({errored.map((v) => v.value).join(", ")})</small>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
