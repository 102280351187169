import { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import { RoutePath } from "shared/constants";

const useAuthRedirect = () => {
  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authUser === null && location.pathname !== RoutePath.Login) {
      navigate(RoutePath.Login, { state: { from: location.pathname } });
    }
  }, [authUser, navigate, location]);

  useEffect(() => {
    if (authUser) {
      if (location.pathname === RoutePath.Login) {
        navigate(RoutePath.Home);
      }
    }
  }, [authUser, navigate, location]);
};

export default useAuthRedirect;
