/* eslint-disable no-nested-ternary */
import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "components/ui/checkbox";
import { IProduct } from "integrations/firebase/collections";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { TableStatusBadges } from "components/GenerationStatusBadge/TableStatusBadges";

export const columns: ColumnDef<IProduct>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <div>
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      </div>
    ),
  },
  {
    accessorKey: "title",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Select all" />
    ),
    cell: ({ row }) => (
      <div className="whitespace-normal">{row.getValue("title")}</div>
    ),
  },
  {
    accessorKey: "generated",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="AI Generated"
        className="items-center justify-center"
      />
    ),
    cell: ({ row }) => {
      return <TableStatusBadges row={row} />;
    },
  },
];
