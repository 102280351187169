import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useErrorToast } from "hooks/useErrorToast";
import { ProfileForm } from "./ProfileForm";

export function ProfileModal() {
  const { authUser, userData, loading, updateUserProfile, error } = useAuth();
  const { t } = useTranslation("profile");

  useErrorToast(error);

  if (!userData || loading) {
    return;
  }

  const showModal = Boolean(!userData.givenName || !userData.familyName);

  return (
    <Dialog open={showModal}>
      <DialogContent hideClose>
        <DialogHeader>
          <DialogTitle>{t("profile:setup.title")}</DialogTitle>
          <DialogDescription hidden>
            {t("profile:setup.description")}
          </DialogDescription>
        </DialogHeader>

        <ProfileForm
          userId={userData.id}
          photoUrl={authUser?.photoURL ?? undefined}
          email={userData.email}
          firstName={userData.givenName}
          lastName={userData.familyName}
          updateUserProfile={updateUserProfile}
        />
      </DialogContent>
    </Dialog>
  );
}
