import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import qs from "query-string";

import entries from "lodash/entries";
import { SearchQuery, SearchType } from "./types";
import { paramsToSearchQuery } from "./utils";

const DEFAULT_PAGE_SIZE = 10;

export function queryToParams(query: Partial<SearchQuery>) {
  const filters = entries(query.filters).map(([variable, filter]) => {
    if (!filter) {
      return undefined as never;
    }

    return `${variable}.${filter.comparisonOperator}:${filter.value}`;
  });

  const params: Record<string, string | number | string[] | undefined> = {
    q: query.query || undefined,
    type: query.type === SearchType.QUICK ? undefined : query.type,
    filter: filters.filter(Boolean),
    page: query.page || undefined,
    pageSize: query.pageSize || undefined,
  };

  return qs.stringify(params, {
    encode: false,
    skipNull: true,
    sort: false,
  });
}

export function useSearchQuery() {
  const [params] = useSearchParams();
  const push = useNavigate();
  const location = useLocation();

  const query = useMemo(() => paramsToSearchQuery(params), [params]);

  const setQuery = useCallback(
    (updated: Partial<SearchQuery>) => {
      const value = { ...query, ...updated };

      push(
        { pathname: location.pathname, search: queryToParams(value) },
        { replace: true },
      );
    },
    [query, push, location.pathname],
  );

  return useMemo(() => {
    const value = {
      ...query,
      pagination: {
        pageIndex: query.page || 1,
        pageSize: query.pageSize || DEFAULT_PAGE_SIZE,
      },
    };

    return [value, setQuery] as const;
  }, [query, setQuery]);
}
