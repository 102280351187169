import { TFunction } from "i18next";
import { OnixGenerationModalStatus } from "pages/ProjectDetails/types/types";

type ModalVariant = "primary" | "danger";

interface OnixExportData {
  title: string;
  description: string;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmIconName?: string;
  variant: ModalVariant;
}

export const getOnixExportData = (
  t: TFunction,
  status: OnixGenerationModalStatus,
): OnixExportData => {
  const baseConfig: OnixExportData = {
    variant: "primary",
    title: t("projectDetails:modals.exportOnix.requested.title"),
    description: t("projectDetails:modals.exportOnix.requested.description"),
    cancelLabel: "",
    confirmLabel: "",
    confirmIconName: "",
  };

  switch (status) {
    case OnixGenerationModalStatus.ERROR:
      return {
        ...baseConfig,
        title: t("projectDetails:modals.exportOnix.errors.title"),
        description: t("projectDetails:modals.exportOnix.errors.description"),
        cancelLabel: t("projectDetails:modals.exportOnix.actions.close"),
        variant: "danger",
      };
    case OnixGenerationModalStatus.SUCCESS:
      return {
        ...baseConfig,
        title: t("projectDetails:modals.exportOnix.success.title"),
        description: t("projectDetails:modals.exportOnix.success.description"),
        confirmLabel: t("projectDetails:modals.exportOnix.actions.download"),
        confirmIconName: "download",
      };
    case OnixGenerationModalStatus.REQUESTED:
      return {
        ...baseConfig,
        cancelLabel: t("projectDetails:modals.exportOnix.actions.cancel"),
      };
    default:
      return {
        ...baseConfig,
        cancelLabel: t("projectDetails:modals.exportOnix.actions.close"),
      };
  }
};
