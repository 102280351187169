import { useTranslation } from "react-i18next";
import { Input } from "components/ui/input";
import { SearchType } from "hooks/search/types";

interface TextInputProps {
  value?: string;
  type: SearchType;
  autoFocus?: boolean;
  disabled?: boolean;

  onValueChange(value: string): void;
}

export function TextInput({
  value = "",
  onValueChange,
  type,
  autoFocus = false,
  disabled = false,
}: TextInputProps) {
  const { t } = useTranslation(["books"]);
  const placeholder =
    type === SearchType.QUICK
      ? t(`books:search.placeholder.quick`)
      : t(`books:search.placeholder.semantic`);

  return (
    <Input
      id="search-input"
      autoComplete="off"
      autoFocus={autoFocus}
      disabled={disabled}
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
      placeholder={placeholder}
      aria-label={placeholder}
      className="border-l-0 rounded-lg rounded-l-none h-full"
    />
  );
}
