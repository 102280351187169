import {
  DialogDescription,
  DialogTitle,
  DialogHeader,
} from "components/ui/dialog";

import { IconProps } from "components/icon/Icon";
import { ModalIcon } from "./ModalIcon";
import { ModalVariant } from "./Modal.types";

export function ModalHeader({
  title,
  description,
  iconName,
  variant,
}: {
  title: string;
  description: string;
  iconName: IconProps["name"];
  variant: ModalVariant;
}) {
  return (
    <DialogHeader>
      <div className="mb-4">
        <ModalIcon
          className="h-12 w-12 p-3"
          icon={iconName}
          variant={variant}
        />
      </div>
      <DialogTitle className="pb-1">{title}</DialogTitle>
      <DialogDescription className="text-m text-secondary-400 font-light">
        {description}
      </DialogDescription>
    </DialogHeader>
  );
}
