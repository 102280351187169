import { ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TitleEditor } from "./TitleEditor";

interface TabHeaderProps {
  title: string;
  subtitle: string | ReactNode;
  isEditable?: boolean;

  onEdit?(value: string): void;
}

export const TabHeader = ({
  title,
  subtitle,
  onEdit,
  isEditable,
}: TabHeaderProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditing] = useState(() => searchParams.get("newProject") === "true");

  useEffect(() => {
    if (searchParams.has("newProject")) {
      searchParams.delete("newProject");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return (
    <div>
      <TitleEditor
        value={title}
        autoFocus={isEditing}
        onChange={onEdit}
        disabled={!isEditable}
      />
      <div
        className="text-secondary-400 text-s leading-tight pt-1"
        data-testid="header-subtitle"
      >
        {subtitle}
      </div>
    </div>
  );
};
