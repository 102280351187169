import { useEffect, useMemo, useState } from "react";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  RowSelectionState,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { EditFeature, SelectedAllFeature } from "shared/react-table/features";

interface TableProps<T extends { id?: string }> {
  columns: any[];
  data: T[];
  enableRowSelection?: boolean;
  totalRecords: number;
  paginationState?: {
    pagination: {
      pageIndex: number;
      pageSize: number;
    };
    setPagination?: OnChangeFn<PaginationState>;
  };
  rowSelection?: RowSelectionState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
}

export const useTable = <T extends { id?: string }>({
  columns,
  data,
  enableRowSelection = false,
  totalRecords,
  paginationState,
  rowSelection,
  setRowSelection,
}: TableProps<T>) => {
  const pagination = paginationState?.pagination || {
    pageIndex: 0,
    pageSize: 10,
  };
  const setPagination = paginationState?.setPagination;
  const [localRowSelection, setLocalRowSelection] = useState({});
  const [editing, setEditing] = useState({});
  const [selectedAll, onSelectedAllChange] = useState(false);

  const listDataMemo = useMemo(() => data || [], [data]);

  const table = useReactTable<T>({
    _features: [EditFeature, SelectedAllFeature],
    data: listDataMemo,
    columns,
    state: {
      rowSelection: rowSelection ?? localRowSelection,
      pagination,
      editing,
      selectedAll,
    },
    getRowId: (row, index) => row.id ?? String(index),
    manualPagination: true,
    rowCount: totalRecords,
    enableRowSelection,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection ?? setLocalRowSelection,
    onEditChange: setEditing,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onSelectedAllChange,
  });

  const isAllPageRowsSelected = table.getIsAllPageRowsSelected();

  useEffect(() => {
    if (!isAllPageRowsSelected && selectedAll) {
      onSelectedAllChange(false);
    }
  }, [selectedAll, isAllPageRowsSelected]);

  const memoizedTable = useMemo(() => table, [table]);

  return { table: memoizedTable as Table<T> };
};
