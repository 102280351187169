import { useState, useCallback } from "react";
import { useApi } from "hooks/useApi";
import { useAnalytics } from "contexts/AnalyticsContext";

import { IProduct } from "integrations/firebase/collections";
import { OnixGenerationModalStatus } from "pages/ProjectDetails/types/types";

export const useOnixGeneration = () => {
  const api = useApi();
  const { gaEvent } = useAnalytics();
  const [showOnixExportModal, setShowOnixExportModal] = useState(false);
  const [onixUrl, setOnixUrl] = useState<string | null>(null);
  const [generatingStatus, setGeneratingStatus] =
    useState<OnixGenerationModalStatus>(OnixGenerationModalStatus.IDLE);
  const handleOnixExportButtonClick = useCallback(
    async (selectedProducts: IProduct[]) => {
      setOnixUrl(null);
      setGeneratingStatus(OnixGenerationModalStatus.REQUESTED);
      setShowOnixExportModal(true);
      gaEvent({
        type: "export_books",
        payload: { books_count: selectedProducts.length, export_type: "onix" },
      });

      try {
        const result = await api.products.exportProducts(
          selectedProducts.map((p) => p.isbn),
        );
        if (result?.filePath) {
          setGeneratingStatus(OnixGenerationModalStatus.SUCCESS);
          setOnixUrl(result.filePath);
        }
      } catch (error) {
        setGeneratingStatus(OnixGenerationModalStatus.ERROR);
      }
    },
    [api.products, gaEvent],
  );

  return {
    showOnixExportModal,
    setShowOnixExportModal,
    handleOnixExportButtonClick,
    generatingStatus,
    setGeneratingStatus,
    onixUrl,
  };
};
