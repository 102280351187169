import { useTranslation } from "react-i18next";
import { useProduct } from "contexts/ProductContext";
import { ButtonCopyTextToClipboard } from "components/button/ButtonCopyTextToClipboard";
import { useBookDescription } from "contexts/BookDescriptionContext";
import { Button } from "components/button";
import { GenerationStatus } from "integrations/firebase/collections";

export const GenerateHeader = () => {
  const { t } = useTranslation(["general", "productDetails"]);
  const { product } = useProduct();

  const {
    handleCopyDoneGeneratedBookDescription,
    setShowDetailsGenerateBookDescription,
  } = useBookDescription();
  const generated = product?.generated;

  const { text: generatedBookDescription } = generated?.description?.data ?? {};

  const canReOptimise =
    product?.generated.description?.status &&
    product?.generated.description?.status !== GenerationStatus.IMPORTED &&
    product?.generated.description?.status !== GenerationStatus.REQUESTED &&
    product?.generated.description?.status !== GenerationStatus.ERROR;

  return (
    <div className="flex items-center justify-between min-h-12 pl-5 pr-3 bg-primary-50">
      <p className="font-medium">
        {t("productDetails:bookDescription.tabGenerate.optimiseDescription")}
      </p>

      <div className="flex items-center">
        {canReOptimise && (
          <Button
            label={t("general:buttons.reGenerate")}
            variant="ghost"
            icon="loader"
            className="px-2"
            onClick={() => setShowDetailsGenerateBookDescription(true)}
          />
        )}

        <ButtonCopyTextToClipboard
          textToCopy={generatedBookDescription || ""}
          tooltipText={t("general:tooltips.copyToClipboard")}
          onDone={handleCopyDoneGeneratedBookDescription}
          buttonText={`${t("general:buttons.copy")}`}
        />
      </div>
    </div>
  );
};
