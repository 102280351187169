import React, { memo } from "react";
import { Modal } from "components/modal/Modal";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { getEvaluationsCollection } from "integrations/firebase/collections/evaluations";
import { Icon } from "components/icon/Icon";
import { useTranslation } from "react-i18next";

interface EvaluationProgressModalProps {
  evaluationId: string;

  onClose(): void;

  onConfirm(): void;
}

export const EvaluationProgressModal = memo(
  ({ evaluationId, onClose, onConfirm }: EvaluationProgressModalProps) => {
    const { t } = useTranslation(["books"]);

    const [evaluation] = useDocumentData(
      doc(getEvaluationsCollection(), evaluationId),
    );

    if (!evaluation) {
      return null;
    }

    const { status, createdAt } = evaluation;

    return (
      <Modal
        open
        onOpenChange={(open) => !open && onClose()}
        id="evaluation-progress"
        mainIconName="loader"
        title={t("books:evaluation.modal.progress.title")}
        description={t("books:evaluation.modal.progress.description")}
        variant="primary"
        confirmLabel={t("books:evaluation.modal.progress.actions.confirm")}
        cancelLabel={t("books:evaluation.modal.progress.actions.cancel")}
        onConfirm={onConfirm}
      >
        <div className="shadow-sm border-border border rounded-md space-x-3 flex p-3 items-center">
          <div className="border rounded-md p-2.5">
            <Icon name="chart-pie" />
          </div>

          <div className="space-y-1 flex-1">
            <div className="text-sm font-medium">
              {createdAt.toDate().toLocaleDateString()}
            </div>
            <div className="text-xs text-secondary-400">
              <span className="font-medium">
                {t("books:evaluation.modal.progress.status.label")}:{" "}
              </span>
              <span className="font-semibold">{status}</span>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

EvaluationProgressModal.displayName = "EvaluationProgressModal";
