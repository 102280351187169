import { useMemo } from "react";
import { ToggleGroup, ToggleGroupItem } from "components/ui/toggle-group";
import { useTrends } from "contexts/TrendsContext";

export const CountryToggleGroup = () => {
  const {
    top3CountryCodes,
    countries,
    setSelectedCountryCode,
    selectedCountryCode,
  } = useTrends();

  const onValueChange = (value: string) => {
    setSelectedCountryCode(value);
  };
  const top3Countries = useMemo(
    () =>
      countries.filter(
        (country) =>
          top3CountryCodes.findIndex(
            (code) => code === country.country_code,
          ) !== -1,
      ),
    [countries, top3CountryCodes],
  );
  return (
    <ToggleGroup
      value={selectedCountryCode}
      variant="outline"
      type="single"
      className="max-lg:hidden"
      onValueChange={onValueChange}
    >
      {top3Countries.map((country) => (
        <ToggleGroupItem
          key={country.country_code}
          value={country.country_code}
          aria-label={country.country_name}
        >
          <p className="text-sm font-normal">{country.country_name}</p>
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  );
};
