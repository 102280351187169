import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganisationOnixUploadFileUploadDialog } from "./OrganisationOnixUploadFileUploadDialog";

export const OrganisationOnixUploadFileUpload = ({
  onFileUpload,
}: {
  onFileUpload: (acceptedFiles: File[]) => void;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation(["settings"]);

  const handleFileUpload = useCallback(
    (acceptedFiles: File[]) => {
      setDialogOpen(false);
      onFileUpload(acceptedFiles);
    },
    [onFileUpload],
  );

  return (
    <div className="flex space-between align-center justify-center">
      <div className="flex-grow">
        <p>{t("settings:organisation.onix.fileUpload.title")}</p>
        <p className="text-secondary-400 pt-1">
          {t("settings:organisation.onix.fileUpload.description")}
        </p>
      </div>
      <div>
        <OrganisationOnixUploadFileUploadDialog
          open={dialogOpen}
          onOpenChange={setDialogOpen}
          onFileUpload={handleFileUpload}
        />
      </div>
    </div>
  );
};
