import { Row } from "@tanstack/react-table";
import { FirestoreGeneratedStatus } from "__generated__/models";
import { IProduct } from "integrations/firebase/collections";
import {
  filterQualifiers,
  filterSubjects,
} from "pages/ProductDetails/tabs/thema/utils";

export const getBadgeStatusFromRow = (row: Row<IProduct>) => {
  const subjects = row.original.subjects?.generated;
  const { keywords, description } = row.original.generated || {};

  const parsedSubjectStatus =
    subjects &&
    (filterQualifiers(subjects).length || filterSubjects(subjects).length)
      ? FirestoreGeneratedStatus.COMPLETED
      : FirestoreGeneratedStatus.IMPORTED;
  return {
    subjectsStatus: parsedSubjectStatus,
    keywordsStatus: keywords?.status,
    descriptionStatus: description?.status,
  };
};
