import { FirestoreProductSubject } from "__generated__/models";
import { Model } from "integrations/firebase/collections/model";
import { EmptyListProps } from "components/EmptyList/EmptyListTypes";
import { ButtonProps } from "components/button/Button.types";

export interface ThemaTabProps {
  title: string;
  key: "generated" | "database";
  content: React.ReactNode;
}

export enum SubjectSchemeIdentifier {
  ThemaSubjectCategory = "themaSubjectCategory",
  ThemaPlaceQualifier = "themaPlaceQualifier",
  ThemaTimePeriodQualifier = "themaTimePeriodQualifier",
  ThemaStyleQualifier = "themaStyleQualifier",
  ThemaEducationalPurposeQualifier = "themaEducationalPurposeQualifier",
  ThemaInterestAgeSpecialInterestQualifier = "themaInterestAgeSpecialInterestQualifier",
  Keyword = "keyword",
}

export type ThemaItem = Model<FirestoreProductSubject> & {
  subjectSchemeIdentifier: SubjectSchemeIdentifier;
};

export interface BaseListContentProps {
  isEmptyList: boolean;
  emptyListProps?: Partial<EmptyListProps>;
  title: string;
  headerButtonProps?: ButtonProps;
  emptyComponent?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
}
