import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Label } from "components/ui/label";
import { SharedDataSchema } from "__generated__/models/schemas.gen";

export interface DetailsGenerateBookDescriptionProps {
  selectedTarget: string | null;
  setSelectedTarget: (selectedTarget: string | null) => void;
}

export const SelectedTargetsSelect: React.FC<
  DetailsGenerateBookDescriptionProps
> = ({ selectedTarget, setSelectedTarget }) => {
  const { t } = useTranslation(["productDetails"]);
  const { organisation } = useAuth();
  let targets = organisation?.targets;

  if (!targets) {
    targets = [...SharedDataSchema.properties.defaultTargetGroups.example];
  }

  return (
    <div className="grid gap-3">
      <Label className="text-s">
        {t(
          "productDetails:bookDescription.tabGenerate.detailsGenerate.targetGroup",
        )}
      </Label>
      <Select onValueChange={setSelectedTarget} value={selectedTarget || ""}>
        <SelectTrigger aria-label="target-groups">
          <SelectValue
            placeholder={t(
              "productDetails:bookDescription.tabGenerate.detailsGenerate.targetGroupPlaceholder",
            )}
          />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {targets.map((target) => (
              <SelectItem key={target} value={target} testId={target}>
                {target}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
