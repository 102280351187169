import { Header } from "./header";
import { SelectedTable } from "./components/table/SelectedTable";

export const SelectedSearchTermsPage = () => {
  return (
    <div className="space-y-6">
      <Header />
      <SelectedTable />
    </div>
  );
};
