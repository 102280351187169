import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/modal/Modal";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import { Button } from "components/button";
import { PROJECT_TYPE } from "integrations/firebase/collections";
import { buildProjectUrl, openInNewTab } from "utils/helpers";
import { toast } from "utils/toast";
import {
  SAVE_AS_PROJECT_BOOKS_LIMIT,
  SAVE_AS_PROJECT_BOOKS_WARNING_LIMIT,
} from "shared/constants";
import { useApi } from "hooks/useApi";
import { useBacklist } from "contexts/BacklistContext";
import { useProjects } from "../../Projects/contexts/ProjectsContext";

export const SaveAsProjectButton = ({
  isSelectedAll,
  selectedBooksCount,
  selectedIsbns,
  allBooksCount,
}: {
  isSelectedAll?: boolean;
  selectedBooksCount: number;
  selectedIsbns: string[];
  allBooksCount: number;
}) => {
  const { createProject } = useProjects();
  const { t } = useTranslation(["books", "general"]);
  const { searchRequestParams } = useBacklist();
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const api = useApi();

  const isLimitReached = selectedBooksCount > SAVE_AS_PROJECT_BOOKS_LIMIT;
  const isWarningReached =
    selectedBooksCount > SAVE_AS_PROJECT_BOOKS_WARNING_LIMIT && !isLimitReached;
  const createButtonDisabled = isLimitReached || selectedBooksCount === 0;

  const handleSaveProject = async () => {
    let ids = selectedIsbns;

    if (isSelectedAll && selectedIsbns.length < allBooksCount) {
      setIsLoading(true);

      const items = await api.search({
        ...searchRequestParams,
        page: 1,
        per_page: 250,
      });

      ids = items?.hits?.map((hit) => hit.document.identifier) || [];
    }

    const project = await createProject(
      t("general:newProjectName"),
      ids,
      PROJECT_TYPE.ISBNS,
    );

    openInNewTab(`${buildProjectUrl(project!)}?newProject=true`);

    toast.success(t("books:selectedBooks.toasts.info.createProjectDone"));
    setIsWarningOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        id="save-project"
        loading={isLoading}
        open={isWarningOpen}
        variant="warning"
        title={t("books:selectedBooks.saveProjectWarningModal.header")}
        description={t(
          "books:selectedBooks.saveProjectWarningModal.description",
          { count: SAVE_AS_PROJECT_BOOKS_WARNING_LIMIT },
        )}
        confirmLabel={t("books:selectedBooks.saveAsProject")}
        confirmIconName="loader"
        cancelLabel={t("general:buttons.cancel")}
        onOpenChange={() => setIsWarningOpen(false)}
        onConfirm={handleSaveProject}
      />
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <span>
            <Button
              label={t("books:selectedBooks.saveAsProject")}
              onClick={
                isWarningReached
                  ? () => setIsWarningOpen(true)
                  : handleSaveProject
              }
              icon="folder-plus"
              disabled={createButtonDisabled}
            />
          </span>
        </TooltipTrigger>
        {createButtonDisabled ? (
          <TooltipContent
            side="bottom"
            className="font-light text-center shadow-tooltip text-neutral-600 whitespace-pre px-5 z-10 border-none leading-relaxed"
            sideOffset={6}
            align="end"
          >
            <TooltipArrow
              className="fill-white drop-shadow-sm"
              width={12}
              height={6}
            />
            <p className="whitespace-pre">
              {isLimitReached
                ? t("books:selectedBooks.limit", {
                    count: SAVE_AS_PROJECT_BOOKS_LIMIT,
                  })
                : t("books:selectedBooks.noBooks")}
            </p>
          </TooltipContent>
        ) : null}
      </Tooltip>
    </>
  );
};
