import { collection, CollectionReference, Timestamp } from "firebase/firestore";
import { db } from "integrations/firebase/firestore";
import { EvaluationResponse } from "__generated__/api";
import { converter } from "../utils";

interface FirestoreEvaluation extends Omit<EvaluationResponse, "createdAt"> {
  createdAt: Timestamp;
}

export const getEvaluationsCollection = () =>
  collection(db, "evaluations").withConverter(
    converter,
  ) as CollectionReference<FirestoreEvaluation>;
