import { Row } from "@tanstack/react-table";
import {
  getProjectsCollection,
  IProject,
} from "integrations/firebase/collections";
import { deleteDoc, doc } from "firebase/firestore";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { EllipsisVertical, Trash } from "lucide-react";

import { useDialog } from "hooks/useDialog";
import { useProjects } from "pages/Projects/contexts/ProjectsContext";
import { useTranslation } from "react-i18next";
import { Modal } from "components/modal/Modal";

interface DropdownMenuProps {
  isOwner?: boolean;
  row: Row<IProject>;
}

export const TableMenu = ({ isOwner, row }: DropdownMenuProps) => {
  const { isOpen, onOpen, onClose, onOpenChange } = useDialog();
  const { fetchProjects } = useProjects();
  const { t } = useTranslation(["general, components"]);
  const handleDelete = async () => {
    await deleteDoc(doc(getProjectsCollection(), row.original.id));
    await fetchProjects();

    onClose();
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger
          disabled={!isOwner}
          data-testid="table-menu-button"
        >
          <div
            className={`flex flex-row items-center gap-2 z-40 ${isOwner ? "text-secondary-500" : "text-secondary-300"}`}
          >
            <EllipsisVertical size={16} />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem onClick={onOpen}>
            <Trash className="mr-2 h-3.5 w-3.5 text-system-error" />
            {t("general:buttons.delete")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Modal
        id="delete-project"
        variant="danger"
        onOpenChange={onOpenChange}
        open={isOpen}
        onConfirm={handleDelete}
        title={t("components:projectsTable.deleteProject.title")}
        description={t("components:projectsTable.deleteProject.description", {
          projectTitle: row.original.name,
        })}
        confirmLabel={t("general:buttons.delete")}
        confirmIconName="trash"
        cancelLabel={t("general:buttons.cancel")}
      />
    </>
  );
};
