import { Button } from "components/button";
import { Loader } from "components/loading/Loader";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { useProduct } from "contexts/ProductContext";
import { useProductActions } from "hooks/useProductActions";
import { useTranslation } from "react-i18next";
import { EmptyStateContent } from "./EmptyStateContent";
import { useKeywordsData } from "./useKeywordsData";

export const FinalKeywordsEmpty = () => {
  const { t } = useTranslation(["general", "productDetails"]);
  const { product } = useProduct();
  const { generatedKeywords } = useKeywordsData();
  const { autofillProductKeywords } = useProductActions();

  const { features } = useFeatureFlags();

  const onAutofill = () => {
    if (!product) return;
    autofillProductKeywords(product);
  };

  const isAutofilling = !!product?.generated.keywords.autoFill;
  const hasKeywords = generatedKeywords.length > 0;

  if (!features.rankKeywords) {
    return <EmptyStateContent />;
  }

  return (
    <EmptyStateContent>
      <Button
        disabled={isAutofilling || !hasKeywords}
        size="small"
        icon={!isAutofilling ? "sparkles" : undefined}
        onClick={onAutofill}
      >
        {isAutofilling && <Loader className="size-4 border-white" />}
        {t("productDetails:finalKeywords.empty.autofill")}
      </Button>
    </EmptyStateContent>
  );
};
