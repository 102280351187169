import React from "react";
import { Modal } from "components/modal/Modal";
import { useTranslation } from "react-i18next";
import { ModalVariant } from "components/modal/Modal.types";

import { IconProps } from "components/icon/Icon";
import { OnixGenerationModalStatus } from "pages/ProjectDetails/types/types";
import { downloadFile } from "utils/helpers";
import { GeneratingSkeleton } from "./GeneratingSkeleton";
import { getOnixExportData } from "./utils";

export const OnixGenerationModal = ({
  showOnixExportModal,
  setShowOnixExportModal,
  generatingStatus,
  setGeneratingStatus,
  onixUrl,
}: {
  showOnixExportModal: boolean;
  setShowOnixExportModal: (show: boolean) => void;
  setGeneratingStatus: (status: OnixGenerationModalStatus) => void;
  generatingStatus: OnixGenerationModalStatus;
  onixUrl?: string | null;
}) => {
  const { t } = useTranslation(["projectDetails"]);

  const {
    title,
    description,
    cancelLabel,
    confirmLabel,
    variant,
    confirmIconName,
  } = getOnixExportData(t, generatingStatus);

  const onOpenChange = () => {
    setShowOnixExportModal(false);
    setGeneratingStatus(OnixGenerationModalStatus.IDLE);
  };

  return (
    <Modal
      id="onix-generation"
      variant={variant as ModalVariant}
      title={title}
      description={description}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      confirmIconName={confirmIconName as IconProps["name"]}
      cancelIconName="x"
      open={showOnixExportModal}
      onConfirm={onixUrl ? () => downloadFile(onixUrl) : undefined}
      onOpenChange={onOpenChange}
    >
      {generatingStatus === OnixGenerationModalStatus.REQUESTED ? (
        <GeneratingSkeleton />
      ) : null}
    </Modal>
  );
};
