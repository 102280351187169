import { Button } from "components/button";

type HeaderProps =
  | {
      showAddButton: true;
      inputMode: boolean;
      setInputMode: (inputMode: boolean) => void;
      label: string;
    }
  | {
      showAddButton?: false;
      inputMode?: never;
      setInputMode?: never;
      label: string;
    };

export const FinalHeader = ({
  inputMode,
  setInputMode,
  showAddButton = false,
  label,
}: HeaderProps) => (
  <div className="flex items-center rounded-t-xl justify-between w-full bg-primary-50 h-12 border-b border-b-secondary-200 py-2 ">
    <p className="ml-4 text-s font-semibold text-secondary-800">{label}</p>
    {showAddButton && (
      <Button
        label=""
        variant="link"
        icon="plus"
        onClick={() => setInputMode?.(true)}
        disabled={inputMode}
        testId="add-keyword-button"
        analyticsId="button_add_final_keyword"
      />
    )}
  </div>
);
