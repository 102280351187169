import { Client } from "@hey-api/client-fetch";
import { exportProducts } from "__generated__/api";
import { exportProductsResponseSchema } from "__generated__/api/validation.gen";
import { parseResult } from "../parseHelper";

export class ProductsApi {
  constructor(private client: Client) {}

  async exportProducts(isbns: string[]) {
    if (isbns.length === 0) {
      throw new Error("Product IDs array cannot be empty");
    }

    const response = await exportProducts({
      body: { productIds: isbns },
      client: this.client,
    });

    const result = parseResult(exportProductsResponseSchema, response, {});

    return result?.data;
  }
}
