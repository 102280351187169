import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "components/ui/badge";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import { FirestoreGeneratedStatus } from "__generated__/models";
import { useGenerations } from "contexts/GenerationsContext";
import {
  getLabels,
  getStatusLabel,
  getTooltipLabel,
  statusColors,
} from "./labels";

interface GenerationStatusBadgeProps {
  status?: FirestoreGeneratedStatus;
  labelKey: "keywords" | "description" | "subjects" | "generating" | "error";
  errorMessage?: string;
  productId?: string;
}

export const GenerationStatusBadge = memo(
  ({
    status = FirestoreGeneratedStatus.IMPORTED,
    labelKey,
    errorMessage,
    productId,
  }: GenerationStatusBadgeProps) => {
    const { t } = useTranslation(["components"]);
    const colors = statusColors[status];
    const { generations } = useGenerations();

    const label = useMemo(() => {
      const labels = getLabels(t, colors.icon);
      const resolvedStatus = getStatusLabel(
        labelKey,
        status,
        generations,
        productId,
      );
      return (
        labels.find((l) => l.key === resolvedStatus.toLowerCase()) ||
        labels.find((l) => l.key === labelKey)
      );
    }, [t, colors.icon, status, labelKey, productId, generations]);

    if (!label) {
      return null;
    }

    return (
      <Tooltip>
        <TooltipTrigger>
          <Badge
            data-testid={`badge-${labelKey}`}
            aria-label={`badge-${labelKey}-${status.toLowerCase()}`}
            className={`min-w-11 h-6 p-0 ${colors.bg} ${status === FirestoreGeneratedStatus.REQUESTED || status === FirestoreGeneratedStatus.GENERATING ? "animate-pulse-3" : ""}`}
          >
            {label.icon}
          </Badge>
        </TooltipTrigger>
        <TooltipContent
          side="bottom"
          className="text-center shadow-tooltip text-neutral-600 whitespace-pre z-10 border-none leading-relaxed"
          sideOffset={6}
          data-testid="badge-tooltip"
        >
          <TooltipArrow
            className="fill-white drop-shadow-sm"
            width={12}
            height={6}
          />
          <p>{errorMessage ?? getTooltipLabel(t, label.label, status)}</p>
        </TooltipContent>
      </Tooltip>
    );
  },
);

GenerationStatusBadge.displayName = "GenerationStatusBadge";
