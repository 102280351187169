export interface LaunchDarklyFeatureFlags {
  trendProjects: boolean;
  bookDescription: boolean;
  manualTrends: boolean;
  backlist: boolean;
  themaCodes: boolean;
  flagsAuthLoaded: boolean;
  rankKeywords: boolean;
  onixExport: boolean;
  evaluation: boolean;
}

export function parseFeatureFlags(flags: Partial<LaunchDarklyFeatureFlags>) {
  return {
    trendProjects: flags.trendProjects ?? false,
    bookDescription: flags.bookDescription ?? false,
    manualTrends: flags.manualTrends ?? false,
    backlist: flags.backlist ?? false,
    themaCodes: flags.themaCodes ?? false,
    flagsAuthLoaded: flags.flagsAuthLoaded ?? false,
    rankKeywords: flags.rankKeywords ?? false,
    onixExport: flags.onixExport ?? false,
    evaluation: flags.evaluation ?? false,
  };
}

export type FeatureFlags = ReturnType<typeof parseFeatureFlags>;
