import { UserRole } from "__generated__/api";
import { TFunction } from "i18next";
import { logError } from "shared/services/ErrorReporting";
import startCase from "lodash/startCase";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const getUserRoleLabel = (role: UserRole, t: TFunction) => {
  switch (role) {
    case UserRole.ADMIN:
      return t("settings:roles.ADMIN");
    case UserRole.MEMBER:
      return t("settings:roles.MEMBER");
    case UserRole.SUPER_ADMIN:
      return t("settings:roles.SUPER_ADMIN");
    default: {
      logError(`Unknown role: ${role}`);

      return startCase((role as string).toLowerCase());
    }
  }
};

interface UserRoleLabelProps {
  role: UserRole;
}

export const UserRoleLabel = ({ role }: UserRoleLabelProps) => {
  const { t } = useTranslation(["settings"]);

  const label = useMemo(() => getUserRoleLabel(role, t), [role, t]);

  return <span>{label}</span>;
};
