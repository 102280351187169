import { Route, Routes } from "react-router-dom";
import { OrganisationsProvider } from "contexts/OrganisationsProvider";
import { OrganisationsPage } from "./OrganisationsPage";
import { OrganisationRoutes } from "./organisation/OrganisationRoutes";

export const OrganisationsRoutes = () => {
  return (
    <OrganisationsProvider>
      <Routes>
        <Route index element={<OrganisationsPage />} />
        <Route path=":organisationId/*" element={<OrganisationRoutes />} />
      </Routes>
    </OrganisationsProvider>
  );
};
