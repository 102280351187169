/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ColumnDef } from "@tanstack/react-table";
import { IProject } from "integrations/firebase/collections";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";

import { TFunction } from "i18next";
import { ProfilePicture } from "components/user/ProfilePicture";
import { TableMenu } from "./TableMenu";

export const columns: (t: TFunction) => ColumnDef<IProject>[] = (
  t: TFunction,
) => [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("components:projectsTable.columns.name")}
      />
    ),
    cell: ({ row }) => <div className="w-[40%]">{row.getValue("name")}</div>,
  },
  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("components:projectsTable.columns.date")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex space-x-2">
        {row.original?.createdAt?.toDate().toDateString() ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "books",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("components:projectsTable.columns.books")}
      />
    ),
    cell: ({ row }) => {
      const books = (row.original.isbns as string[]) || [];
      return (
        <div className="flex items-center">
          <span>
            {`${books.length} ${books.length === 1 ? "book" : "books"}`}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "user",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("components:projectsTable.columns.creator")}
      />
    ),
    cell: ({ row }) => {
      const user = row.getValue("user") as {
        displayName: string;
        photoURL: string;
      };

      return (
        <div className="flex items-center gap-2">
          <ProfilePicture
            name={user?.displayName}
            photoUrl={user?.photoURL}
            className="h-8 w-8"
          />
          <span>{user?.displayName}</span>
        </div>
      );
    },

    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "menu",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="" className="max-w-10" />
    ),
    cell: ({ row }) => {
      const { isOwner } = row;
      return (
        <div className="max-w-10" onClick={(e) => e.stopPropagation()}>
          <TableMenu isOwner={isOwner} row={row} />
        </div>
      );
    },
  },
];
