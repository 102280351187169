/* eslint-disable no-param-reassign */
import { RowData, Table, TableFeature } from "@tanstack/react-table";

interface SelectedAllTableState {
  selectedAll: boolean;
}

export interface SelectedAllOptions {
  enableSelectedAll?: boolean;
  onSelectedAllChange?: (value: boolean) => void;
}

export interface SelectedAllRow {
  getIsSelectedAll: () => boolean;
}

export interface SelectAllInstance {
  setSelectedAll: (value: boolean) => void;
  toggleSelectAll: () => void;
  getSelectedAll: () => boolean;
  getSelectedCount: () => number;
  getSelectedIds: () => string[];
}

export const SelectedAllFeature: TableFeature = {
  getInitialState: (state): SelectedAllTableState => {
    return {
      selectedAll: false,
      ...state,
    };
  },

  getDefaultOptions: (table): SelectedAllOptions => {
    return {
      enableSelectedAll: true,
      onSelectedAllChange: (selectedAll) => {
        table.setState((current: any) => ({
          ...current,
          selectedAll,
        }));
      },
    };
  },

  createTable: <TData extends RowData>(table: Table<TData>): void => {
    table.setSelectedAll = (value) => {
      if (value) {
        table.toggleAllRowsSelected(true);
      } else {
        table.toggleAllRowsSelected(false);
      }

      table.options.onSelectedAllChange?.(value);
    };

    table.toggleSelectAll = () => table.setSelectedAll(!table.getSelectedAll());

    table.getSelectedAll = () => table.getState().selectedAll;

    table.getSelectedCount = () => {
      const { selectedAll } = table.getState();

      if (selectedAll) {
        return table.getRowCount();
      }

      const selection = table.getState().rowSelection ?? {};

      return Object.values(selection).filter(Boolean).length;
    };

    table.getSelectedIds = () => {
      const selection = table.getState().rowSelection ?? {};

      return Object.entries(selection)
        .filter(([, selected]) => selected)
        .map(([id]) => id);
    };
  },
};

declare module "@tanstack/react-table" {
  interface TableState extends SelectedAllTableState {}

  // eslint-disable-next-line unused-imports/no-unused-vars
  interface TableOptionsResolved<TData extends RowData>
    extends SelectedAllOptions {}

  // eslint-disable-next-line unused-imports/no-unused-vars
  interface Table<TData extends RowData> extends SelectAllInstance {}

  // eslint-disable-next-line unused-imports/no-unused-vars
  interface Row<TData extends RowData> extends SelectedAllRow {}
}
