import { Button } from "components/button";

import { SkeletonKeyword } from "components/skeletons/SkeletonKeyword";

import { useTranslation } from "react-i18next";

type GeneratedSubjectsEmptyProps = {
  onClick: () => void;
};
export const GeneratedSubjectsEmpty: React.FC<GeneratedSubjectsEmptyProps> = ({
  onClick,
}) => {
  const { t } = useTranslation(["productDetails"]);
  return (
    <div className="relative min-h-44 w-full items-center flex flex-row justify-center gap-4 my-2">
      <div className="flex justify-center flex-col">
        <SkeletonKeyword
          animated={false}
          className="scale-75 relative animate-none opacity-60"
        />
        <SkeletonKeyword
          animated={false}
          primary
          className="scale-75 relative animate-none"
        />
        <SkeletonKeyword
          animated={false}
          className="scale-75 relative animate-none opacity-60"
        />
      </div>
      <div className=" flex flex-col my-10 gap-3 items-start">
        <p className="font-semibold text-left text-secondary-900">
          {t("thema.sections.noSubjects.generated.title")}
        </p>
        <p className="text-s font-normal text-secondary-400">
          {t("thema.sections.noSubjects.generated.description")}
        </p>
        <Button
          label={t("general:buttons.generate")}
          icon="loader"
          onClick={onClick}
          size="small"
          analyticsId="button_regenerate_keywords"
        />
      </div>
    </div>
  );
};
