import * as React from "react";
import { cn } from "utils/cn";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  testId?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, testId, ...props }, ref) => {
    const hasFocusOutlineNone = className?.includes("focus:outline-none");

    return (
      <input
        ref={ref}
        type={type}
        className={cn(
          "flex h-10 w-full rounded-xl border border-secondary-300 bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-secondary-200 focus-visible:outline-none focus:shadow-outline-secondary disabled:cursor-not-allowed disabled:opacity-50",
          !hasFocusOutlineNone && "focus-visible:outline-none",
          className,
        )}
        data-testid={testId}
        {...props}
      />
    );
  },
);

Input.displayName = "Input";

const InputExample = () => {
  return (
    <div>
      <Input type="email" placeholder="Email" />
      <br />
      <p>
        For full documentation check{" "}
        <a
          className="underline"
          href="https://ui.shadcn.com/docs/components/input"
          target="_blank"
          rel="noreferrer"
        >
          Shadcn documentation
        </a>
      </p>
    </div>
  );
};

export { Input, InputExample };
