import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SignUpSchemaType } from "../utils";

interface MessageFieldProps {
  register: UseFormRegister<SignUpSchemaType>;
  watch: UseFormWatch<SignUpSchemaType>;
}

export const MessageField = ({ register, watch }: MessageFieldProps) => {
  const { t } = useTranslation(["contactUs"]);
  return (
    <div className="space-y-2">
      <Label className="text-s">{t("contactUs:form.message.label")}</Label>
      <Textarea
        {...register("message", { required: true })}
        placeholder={t("contactUs:form.message.placeholder")}
        className="text-s resize-none"
        maxLength={2000}
      />
      <p className="text-secondary-400 text-xs p-0">
        {watch("message").length}/2000
      </p>
    </div>
  );
};
