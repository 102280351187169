import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import { useProduct } from "contexts/ProductContext";
import { useBookDescription } from "contexts/BookDescriptionContext";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { useDialog } from "hooks/useDialog";
import { GenerationStatus } from "integrations/firebase/collections";
import { Warning } from "components/icon/Warning";
import { Modal } from "components/modal/Modal";
import { SelectedTargetsSelect } from "./SelectedTargetsSelect";
import { AdditionalInfo } from "./AdditionalInfo";

export const OptimiseDescriptionModal = () => {
  const { product } = useProduct();
  const { t } = useTranslation(["productDetails", "general"]);
  const {
    generateBookDescription,
    selectedTarget,
    setSelectedTarget,
    specialDetails,
    setSpecialDetails,
    showDetailsGenerateBookDescription,
    setShowDetailsGenerateBookDescription,
  } = useBookDescription();

  const warningModal = useDialog("re-optimisation-warning");

  const hasExistingDescription =
    product?.generated.description?.status &&
    product?.generated.description?.status !== GenerationStatus.IMPORTED &&
    product?.generated.description?.status !== GenerationStatus.ERROR;

  const onGenerateBookDescription = async (skipWarning?: boolean) => {
    if (hasExistingDescription && !skipWarning) {
      warningModal.open();
      return;
    }

    warningModal.close();
    await generateBookDescription(hasExistingDescription);
  };

  return (
    <>
      <div className="absolute inset-0 m-5">
        <Dialog
          open={showDetailsGenerateBookDescription && !warningModal.isOpen}
          onOpenChange={setShowDetailsGenerateBookDescription}
        >
          <DialogContent className="overflow-visible">
            <DialogHeader className="items-start space-y-0 my-0 mb-8">
              <DialogTitle className="">
                {t(
                  "productDetails:bookDescription.tabGenerate.detailsGenerate.title",
                )}
              </DialogTitle>
              <DialogDescription className="text-left">
                {t(
                  "productDetails:bookDescription.tabGenerate.detailsGenerate.description",
                )}
              </DialogDescription>
            </DialogHeader>

            <div className="grid gap-5">
              <SelectedTargetsSelect
                selectedTarget={selectedTarget}
                setSelectedTarget={setSelectedTarget}
              />
              <AdditionalInfo
                specialDetails={specialDetails}
                setSpecialDetails={setSpecialDetails}
              />
            </div>

            <DialogFixedFooter className="flex justify-between gap-2 px-5 items-center">
              {hasExistingDescription && (
                <div className="flex items-center">
                  <Warning
                    pulse={false}
                    className="mr-2 p-1"
                    iconSize="xsmall"
                  />

                  <span className="text-xs text-secondary-400">
                    changes you’ve made will be overwritten
                  </span>
                </div>
              )}

              <div className="flex justify-end gap-2 ml-auto">
                <Button
                  label={t(
                    "productDetails:bookDescription.tabGenerate.actions.cancel",
                  )}
                  onClick={() => setShowDetailsGenerateBookDescription(false)}
                  variant="tertiary"
                  analyticsId="button_generate_book_description_cancel"
                  testId="button-generate-cancel"
                  size="small"
                  analyticsData={{
                    product: product?.title,
                  }}
                />
                <Button
                  label={
                    hasExistingDescription
                      ? t("general:buttons.reGenerate")
                      : t("general:buttons.generate")
                  }
                  size="small"
                  onClick={() => onGenerateBookDescription()}
                  icon="loader"
                  analyticsId="button_generate_book_description"
                  testId="button-generate"
                  analyticsData={{
                    product: product?.title,
                  }}
                />
              </div>
            </DialogFixedFooter>
          </DialogContent>
        </Dialog>
      </div>

      <Modal
        id="re-optimisation-warning"
        open={warningModal.isOpen}
        variant="warning"
        title={t(
          "productDetails:bookDescription.tabGenerate.reOptimisationWarningModal.title",
        )}
        description={t(
          "productDetails:bookDescription.tabGenerate.reOptimisationWarningModal.description",
        )}
        confirmLabel={t(
          "productDetails:bookDescription.tabGenerate.reOptimisationWarningModal.actions.confirm",
        )}
        cancelLabel={t(
          "productDetails:bookDescription.tabGenerate.reOptimisationWarningModal.actions.cancel",
        )}
        confirmIconName="loader"
        onOpenChange={warningModal.onOpenChange}
        onConfirm={() => onGenerateBookDescription(true)}
      />
    </>
  );
};
