import { SkeletonFinalKeyword } from "components/skeletons/SkeletonFinalKeyword";
import { useTranslation } from "react-i18next";

export const EmptyStateContent = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation(["general", "productDetails"]);
  return (
    <div className="flex flex-1 justify-center items-center p-4 overflow-auto">
      <div className="flex flex-col items-center m-8 text-center max-w-md">
        <SkeletonFinalKeyword className="scale-75 opacity-80" />
        <SkeletonFinalKeyword className="scale-75" primary />
        <SkeletonFinalKeyword className="scale-75 opacity-80" />
        <div className="flex flex-col my-8 gap-4 w-full">
          <p className="font-semibold text-secondary-900 break-words">
            {t("productDetails:finalKeywords.empty.header")}
          </p>
          <p className="text-xs text-secondary-500 break-words">
            {t("productDetails:finalKeywords.empty.description")}
          </p>
        </div>
        {children}
      </div>
    </div>
  );
};
