import {
  getAuth,
  Auth,
  GoogleAuthProvider,
  connectAuthEmulator,
} from "firebase/auth";

import { app } from "integrations/firebase/app";

export const auth: Auth = getAuth(app);

auth.tenantId = import.meta.env.VITE_FIREBASE_AUTH_TENANT_ID || null;

// Use auth emulator in local environment
if (import.meta.env.VITE_USE_FIREBASE_EMULATOR === "true") {
  auth.tenantId = "local-tenant";

  connectAuthEmulator(
    auth,
    `http://${import.meta.env.VITE_FIREBASE_EMULATOR_HOST}:${import.meta.env.VITE_FIREBASE_EMULATOR_AUTH_PORT}`,
  );
}

export const googleAuthProvider = new GoogleAuthProvider();
