import { z } from "zod";

export enum SearchFilterComparisonOperator {
  EQUALS = "eq",
  LESS = "lt",
  GREATER = "gt",
}

export enum SearchType {
  QUICK = "quick",
  ISBN = "isbn",
  SEMANTIC = "semantic",
}

export const SearchQueryFilterSchema = z.object({
  value: z.coerce.number(),
  comparisonOperator: z.nativeEnum(SearchFilterComparisonOperator),
});

export type SearchQueryFilter = z.infer<typeof SearchQueryFilterSchema>;

export const SearchQueryFiltersSchema = z.object({
  keywordCount: SearchQueryFilterSchema.nullish(),
});

export type SearchQueryFilters = z.infer<typeof SearchQueryFiltersSchema>;

export const SearchQuerySchema = z.object({
  query: z.string().default(""),
  type: z.nativeEnum(SearchType).optional().default(SearchType.QUICK),
  filters: SearchQueryFiltersSchema,
  page: z.coerce.number().nullish(),
  pageSize: z.coerce.number().nullish(),
});

export type SearchQuery = z.infer<typeof SearchQuerySchema>;
