import { KeywordsLoading } from "components/skeletons/KeywordsLoading";
import { BaseListContent } from "components/thema/BaseListContent";
import { useTranslation } from "react-i18next";

export const KeywordsGenerating = () => {
  const { t } = useTranslation(["productDetails"]);

  return (
    <BaseListContent
      title={t("productDetails:generatedKeywords.header")}
      isEmptyList
      emptyComponent={
        <div className="-translate-y-16 -translate-x-[7.2rem]">
          <KeywordsLoading />
        </div>
      }
      emptyListProps={{
        title: t("productDetails:generatedKeywords.loading"),
        titleStyle: "text-center",
      }}
    />
  );
};
