import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "assets/svg/file-upload-icon.svg?react";

export const OrganisationOnixUploadFileUploadDropZone = ({
  onDrop,
}: {
  onDrop: (acceptedFiles: File[]) => void;
}) => {
  const { t } = useTranslation(["settings"]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "text/xml": [".xml", ".onx", ".onix"] },
  });

  return (
    <div
      {...getRootProps()}
      className="h-52 items-center flex p-12 bg-secondary-25 border border-dashed border-secondary-200 rounded-xl flex-col"
    >
      <input {...getInputProps()} data-testid="file-upload" />
      <FileUploadIcon className="flex-shrink-0" />
      <div className="text-center mt-2">
        {isDragActive ? (
          <p>{t("settings:organisation.onix.fileUpload.dropZone.dropFiles")}</p>
        ) : (
          <p>{t("settings:organisation.onix.fileUpload.dropZone.message")}</p>
        )}
        <p className="mt-1 text-secondary-400 text-s mb-4">
          {t("settings:organisation.onix.fileUpload.dropZone.maxSize", {
            count: 2,
          })}
        </p>
      </div>
    </div>
  );
};
