import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";

export const HomeHeader = () => {
  const { userData } = useAuth();
  const { t } = useTranslation(["home"]);

  const getTimeOfDay = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return t(`home:greeting.morning`, { name: userData?.givenName });
    }
    if (currentHour < 18) {
      return t(`home:greeting.afternoon`, { name: userData?.givenName });
    }
    return t(`home:greeting.evening`, { name: userData?.givenName });
  };

  const greeting = getTimeOfDay();

  return (
    <div className="space-y-2 text-secondary-800 text-center font-semibold">
      <div className="text-2xl">{greeting}</div>
      <div className="text-7xl">{t("home:greeting.description")}</div>
    </div>
  );
};
