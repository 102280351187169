import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SignUpSchemaType } from "../utils";

interface ISBNFieldProps {
  register: UseFormRegister<SignUpSchemaType>;
  errors: FieldErrors<SignUpSchemaType>;
}

export const ISBNField = ({ register, errors }: ISBNFieldProps) => {
  const { t } = useTranslation(["contactUs"]);
  return (
    <div className="space-y-2">
      <Label className="text-s">{t("contactUs:form.isbn.label")}</Label>
      <Input
        {...register("isbn", { minLength: 13, maxLength: 13 })}
        placeholder={t("contactUs:form.isbn.placeholder")}
        aria-invalid={errors.isbn ? "true" : "false"}
        className="text-s"
      />
      {errors.isbn && (
        <p className="text-red-500 text-xs p-0">
          {t("contactUs:form.errors.isbn")}
        </p>
      )}
    </div>
  );
};
