import { Control, useController } from "react-hook-form";
import { SearchQuery, SearchType } from "hooks/search/types";
import { MultiTextInput } from "./MultiTextInput";
import { TextInput } from "./TextInput";

interface TextInputProps {
  control: Control<SearchQuery>;
  type: SearchType;
}

export function SearchInput({ control, type }: TextInputProps) {
  const { field } = useController({ name: "query", control });

  if (type === SearchType.ISBN) {
    return (
      <MultiTextInput value={field.value} onValueChange={field.onChange} />
    );
  }

  return (
    <TextInput
      value={field.value}
      autoFocus
      onValueChange={field.onChange}
      type={type}
    />
  );
}
