import { memo, useEffect } from "react";
import { LucideX } from "lucide-react";
import { useBacklist } from "contexts/BacklistContext";
import { useErrorToast } from "hooks/useErrorToast";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { SearchQuery, SearchType } from "hooks/search/types";
import debounce from "lodash/debounce";
import { SearchTypeDropdown } from "./input/SearchTypeDropdown";
import { SearchFilters } from "./filters/SearchFilters";
import { SearchInput } from "./input/SearchInput";
import { SearchResultsCountDropdown } from "./input/SearchResultsCountDropdown";

interface SearchToolbarProps {
  onSearch(): void;
}

export const SearchToolbar = memo(({ onSearch }: SearchToolbarProps) => {
  const { searchQuery, setSearchQuery, error } = useBacklist();

  const form = useForm<SearchQuery>({
    defaultValues: searchQuery,
  });

  const { control, handleSubmit, watch, setValue, reset } = form;

  useEffect(() => {
    // Don't reset if the user is typing to not affect them typing
    if (document.activeElement?.id === "search-input") {
      return;
    }

    reset(searchQuery);
  }, [searchQuery, reset]);

  useEffect(() => {
    const setSearchQueryDebounced = debounce(setSearchQuery, 300);

    const subscription = watch((value, { name }) => {
      // Reset the page index when the search query changes
      const update = { ...value, page: null };

      // Slow down the update when typing
      const fn = name === "query" ? setSearchQueryDebounced : setSearchQuery;

      fn(update as SearchQuery);
      onSearch();
    });

    return () => subscription.unsubscribe();
  }, [watch, setSearchQuery, onSearch]);

  const type = useWatch({ control, name: "type" });
  const query = useWatch({ control, name: "query" });

  useErrorToast(error);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(setSearchQuery)}
        className="flex space-x-3 lg:mr-10"
      >
        {type !== SearchType.ISBN && <SearchFilters control={control} />}

        <div className="flex relative flex-1">
          <SearchTypeDropdown control={control} />
          <SearchInput control={control} type={type} />

          {query && (
            <button
              type="button"
              onClick={() => setValue("query", "")}
              className="w-12 h-full absolute right-0 flex items-center justify-center"
            >
              <LucideX className="h-5 w-5" />
            </button>
          )}
        </div>

        {type === SearchType.SEMANTIC && (
          <SearchResultsCountDropdown control={control} />
        )}
      </form>
    </FormProvider>
  );
});

SearchToolbar.displayName = "SearchToolbar";
