import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { IProject } from "integrations/firebase/collections";
import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "../integrations/firebase/firestore";
import { converter } from "../integrations/firebase/utils";

interface ProjectContextType {
  project?: IProject;
  loading?: boolean;
  error?: string;
}

export const ProjectContext = createContext<ProjectContextType>(
  undefined as never,
);

interface ProjectProviderProps {
  id: string;
}

export function ProjectProvider({
  id,
  children,
}: PropsWithChildren<ProjectProviderProps>) {
  const [project, loading, error] = useDocumentData<IProject>(
    doc(db, "projects", id).withConverter(converter),
  );

  const value = useMemo(
    () => ({
      project,
      loading,
      error: error?.message,
    }),
    [project, loading, error],
  );

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
}

export const useProject = () => useContext(ProjectContext) || {};
