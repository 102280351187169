import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { IProduct } from "integrations/firebase/collections";
import { doc, FirestoreDataConverter } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "../integrations/firebase/firestore";

interface ProductContextType {
  product?: IProduct;
  loading?: boolean;
  error?: string;
}

export const ProductContext = createContext<ProductContextType>(
  undefined as never,
);

interface ProductProviderProps {
  id: string;
}

const productConverter: FirestoreDataConverter<IProduct> = {
  fromFirestore(snapshot) {
    const data = snapshot.data() as IProduct;
    return {
      ...data,
      id: snapshot.id,
      isbn: snapshot.id,
    };
  },
  toFirestore: (data) => data,
};

export function ProductProvider({
  id,
  children,
}: PropsWithChildren<ProductProviderProps>) {
  const [product, loading, error] = useDocumentData(
    doc(db, "productsNew", id).withConverter(productConverter),
  );
  const value = useMemo(
    () => ({
      product,
      loading,
      error: error?.message,
    }),
    [product, loading, error?.message],
  );

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
}

export const useProduct = () => useContext(ProductContext);
