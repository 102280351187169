import * as React from "react";
import { AuthHeader } from "components/layout/AuthHeader";
import { ContactUsContent } from "./ContactUsContent";

export const ContactUsPage = () => (
  <>
    <AuthHeader displayNavigation={false} />
    <ContactUsContent />
  </>
);
