import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { IProduct } from "integrations/firebase/collections";
import { formatFirestoreTimestamp } from "shared/utils";

enum ExportProductColumns {
  Isbn = "ISBN",
  Title = "Title",
  Author = "Author",
  Publisher = "Publisher",
  PublishedDate = "Published date",
  Genre = "Genre",
  Form = "Form",
  DatabaseDescription = "Database Description",
  GeneratedDescription = "Generated Description",
  DatabaseKeywords = "Database Keywords",
  GeneratedKeywords = "Generated Keywords",
  FinalList = "Final List",
}

const formatProductProperty = (property?: string[] | any): string => {
  const EMPTY_SPACE = " ";

  if (property === null || property === undefined) {
    return EMPTY_SPACE;
  }

  return (
    (Array.isArray(property) ? property.join(";") : String(property)) ||
    EMPTY_SPACE
  );
};

const formatProducts = (products: IProduct[]) =>
  products.map((p) => ({
    [ExportProductColumns.Isbn]: formatProductProperty(p.isbn),
    [ExportProductColumns.Title]: formatProductProperty(p.title),
    [ExportProductColumns.Author]: formatProductProperty(p.author),
    [ExportProductColumns.Publisher]: formatProductProperty(p.publisher),
    [ExportProductColumns.PublishedDate]: formatProductProperty(
      formatFirestoreTimestamp(p.publishedAt),
    ),
    [ExportProductColumns.Genre]: formatProductProperty(p.genre),
    [ExportProductColumns.Form]: formatProductProperty(
      p.productGroupDescription,
    ),
    [ExportProductColumns.DatabaseDescription]: formatProductProperty(
      p.summary,
    ),
    [ExportProductColumns.GeneratedDescription]: formatProductProperty(
      p.generated?.description?.data?.text,
    ),
    [ExportProductColumns.DatabaseKeywords]: formatProductProperty(p.keywords),
    [ExportProductColumns.GeneratedKeywords]: formatProductProperty(
      p.generated?.keywords?.data,
    ),
    [ExportProductColumns.FinalList]: formatProductProperty(p.finalKeywords),
  }));

export const saveProductsAsExcel = async (
  products: IProduct[],
  filename: string,
) => {
  const headers = [
    ExportProductColumns.Isbn,
    ExportProductColumns.Title,
    ExportProductColumns.Author,
    ExportProductColumns.Publisher,
    ExportProductColumns.PublishedDate,
    ExportProductColumns.Genre,
    ExportProductColumns.Form,
    ExportProductColumns.DatabaseDescription,
    ExportProductColumns.GeneratedDescription,
    ExportProductColumns.DatabaseKeywords,
    ExportProductColumns.GeneratedKeywords,
    ExportProductColumns.FinalList,
  ];

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportData = formatProducts(products);

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("data");
  const headerRow = worksheet.addRow(headers);

  headerRow.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.font = { bold: true };
  });

  exportData.forEach((data) => {
    const row = headers.map((header) => data[header]);
    worksheet.addRow(row);
  });

  const excelBuffer = await workbook.xlsx.writeBuffer();
  const dataBlob = new Blob([excelBuffer], { type: fileType });

  saveAs(dataBlob, filename + fileExtension);
};
