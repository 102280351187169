import { useMemo } from "react";
import { SearchQuery } from "hooks/search/types";
import { Control, Controller, useWatch } from "react-hook-form";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { ListFilter } from "lucide-react";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { NumericFilter } from "./NumericFilter";

interface SearchFiltersProps {
  control: Control<SearchQuery>;
}

export const SearchFilters = ({ control }: SearchFiltersProps) => {
  const filters = useWatch({ name: "filters", control });
  const { t } = useTranslation(["books"]);

  const activeFilters = useMemo(
    () => Object.values(filters ?? {}).filter(Boolean).length,
    [filters],
  );

  return (
    <div className="flex flex-row items-end">
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="tertiary" className="px-5">
            <ListFilter className="w-5 h-5" />
            <div className="text-s">{t("books:filters.label")}</div>

            {activeFilters > 0 && (
              <div
                data-testid="search-filters-active"
                className="bg-badge-error-background text-badge-error rounded-full text-[10px] w-4 h-4 flex font-semibold items-center justify-center"
              >
                {activeFilters}
              </div>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent sideOffset={5} align="start">
          <Controller
            control={control}
            name="filters.keywordCount"
            render={({ field }) => (
              <NumericFilter
                onValueChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
