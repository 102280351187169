import { ColumnDef } from "@tanstack/react-table";

import { Timestamp } from "firebase/firestore";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { TFunction } from "i18next";
import { TableStatusBadges } from "components/GenerationStatusBadge/TableStatusBadges";

export const columns: (t: TFunction) => ColumnDef<any>[] = (t) => [
  {
    accessorKey: "isbn",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("selectedSearchTerms:table.columns.isbn")}
      />
    ),
    cell: ({ row }) => <div>{row.getValue("isbn")}</div>,
  },
  {
    accessorKey: "author",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("selectedSearchTerms:table.columns.author")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex space-x-2">
        <span className="max-w-[500px] truncate font-medium">
          {row.getValue("author")}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "title",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("selectedSearchTerms:table.columns.title")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex space-x-2">
        <span className="max-w-[500px] truncate font-medium">
          {row.getValue("title")}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "productGroupDescription",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("selectedSearchTerms:table.columns.productForm")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex items-center">
        <span> {row.getValue("productGroupDescription")}</span>
      </div>
    ),
  },
  {
    accessorKey: "updatedAt",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("selectedSearchTerms:table.columns.updatedOn")}
      />
    ),
    cell: ({ row }) => {
      const publishedAt = row.getValue("updatedAt") as Timestamp;
      return (
        <div className="flex items-center">
          <span>
            {publishedAt?.toDate &&
              publishedAt?.toDate()?.toISOString().slice(0, 10)}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "generated",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("selectedSearchTerms:table.columns.generated")}
        className="items-center justify-center"
      />
    ),
    cell: ({ row }) => {
      return <TableStatusBadges row={row} />;
    },
  },
];
