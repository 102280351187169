import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { RoutePath, TERMS_AND_CONDITIONS_VERSION } from "shared/constants";
import { Trans, useTranslation } from "react-i18next";
import { logError } from "shared/services/ErrorReporting";
import { useAnalytics } from "contexts/AnalyticsContext";
import { FirebaseError } from "firebase/app";
import { Button } from "components/button";
import ReedyLogo from "assets/svg/reedy-logo.svg?react";
import GoogleLogo from "assets/svg/google-logo.svg?react";
import { toast } from "utils/toast";
import { AuthErrorCodes } from "firebase/auth";
import { CircleHelp } from "lucide-react";
import useAuthRedirect from "hooks/auth/useAuthRedirect";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { LoginForm } from "./components/LoginForm";

export type ErrorCodes = (typeof AuthErrorCodes)[keyof typeof AuthErrorCodes];

export const Login: React.FC = () => {
  useAuthRedirect();

  const { t } = useTranslation(["general", "login"]);

  const { signInWithOauth } = useAuth();

  const navigate = useNavigate();

  const [errorLogin, setErrorLogin] = useState<Error>();
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const { gaEvent } = useAnalytics();

  const resetErrorLogin = () => setErrorLogin(undefined);

  useEffect(() => {
    if (errorLogin) {
      toast.error(t("login:error.loginFailed"), {
        onAction: resetErrorLogin,
      });
    }
  }, [errorLogin, t]);

  const loginWithGoogle = useCallback(async () => {
    try {
      await signInWithOauth("google");

      gaEvent({ type: "login", payload: { method: "google" } });

      navigate(RoutePath.Home);

      toast.success(t("login:success.toast.title"));
    } catch (error) {
      const code = (error as FirebaseError)?.code as ErrorCodes;

      if (
        code === AuthErrorCodes.POPUP_CLOSED_BY_USER ||
        code === AuthErrorCodes.EXPIRED_POPUP_REQUEST
      ) {
        return;
      }

      logError(error);
      setErrorLogin(error as Error);
    }
  }, [signInWithOauth, gaEvent, t, navigate]);

  const handleCheckAgreement = useCallback((checked: boolean) => {
    try {
      window.localStorage.setItem(
        "acceptedTermsVersion",
        checked ? TERMS_AND_CONDITIONS_VERSION : "",
      );
    } catch (error) {
      logError(
        `Error saving accepted terms version to local storage: ${error}`,
      );
    }
    setIsAgreementChecked(checked);
  }, []);

  return (
    <div className="container mx-auto grid lg:grid-cols-[320px,1fr] gap-2 px-10 md:px-36 min-h-full">
      <div className="flex flex-col justify-center max-w-xl pt-16 mx-auto">
        <ReedyLogo className="-mx-4 mb-24" width={185} height={96} />

        <div className="space-y-10">
          <div className="space-y-4">
            <div className="text-5xl font-semibold">{t("login:title")}</div>
            <div className="font-medium">{t("login:subtitle")}</div>
          </div>
          <div className="grid gap-3">
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Button
                    label=""
                    onClick={loginWithGoogle}
                    variant="tertiary"
                    size="small"
                    className="!text-s font-normal flex items-center justify-center gap-2 w-full"
                    analyticsId="google-login-button"
                    disabled={!isAgreementChecked}
                  >
                    <GoogleLogo className="h-4 w-4" />
                    {t("login:buttons.google")}
                  </Button>
                </div>
              </TooltipTrigger>
              {!isAgreementChecked && (
                <TooltipContent>
                  <p>{t("login:agreement-required")}</p>
                </TooltipContent>
              )}
            </Tooltip>
            <div className="relative flex items-center justify-center">
              <hr className="absolute left-0 right-0 top-1/2 border-secondary-200" />
              <div className="bg-white text-s text-secondary-600 font-light relative px-2">
                {t("login:or")}
              </div>
            </div>

            <LoginForm isButtonDisabled={!isAgreementChecked} />

            <div className="flex items-start gap-2 text-s text-secondary-400 font-light px-1">
              <input
                type="checkbox"
                checked={isAgreementChecked}
                onChange={(e) => handleCheckAgreement(e.target.checked)}
                className="mt-1.5"
                aria-label={t("login:agreement-required")}
              />
              <div>
                <Trans
                  i18nKey="login:terms-agreement"
                  components={{
                    masterAgreement: (
                      <a
                        className="underline underline-offset-2"
                        href="https://www.reedy.ai/master-agreement"
                        aria-label={t("login:master-agreement")}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                    dataProcessingAgreement: (
                      <a
                        className="underline underline-offset-2"
                        href="https://www.reedy.ai/data-processing-agreement"
                        aria-label={t("login:data-processing-agreement")}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                    termsTrial: (
                      <a
                        className="underline underline-offset-2"
                        href="https://www.reedy.ai/terms-for-the-trial-period"
                        aria-label={t("login:terms-trial")}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                    privacyPolicy: (
                      <a
                        className="underline underline-offset-2"
                        href="https://www.chaptr.com/privacy-policy"
                        aria-label={t("login:privacy-policy")}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1" />

        <Link
          to={RoutePath.ContactUs}
          target="_blank"
          rel="noopener noreferrer"
          className="text-s font-normal text-secondary-400 flex items-center py-10"
        >
          <CircleHelp className="inline-block mr-2 h-5 w-5" />
          {t("login:support")}
        </Link>
      </div>

      <div>
        <img
          alt="login illustration"
          className="object-cover overflow-visible object-left h-full"
          src="/images/login-image.png"
        />
      </div>
    </div>
  );
};
