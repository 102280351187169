import { useCallback, useMemo, useState } from "react";
import { useAnalytics } from "contexts/AnalyticsContext";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { copyToClipboard } from "utils/helpers";
import { FinalHeader } from "components/layout/finalHeader";
import { toast } from "utils/toast";
import { useProduct } from "contexts/ProductContext";
import {
  BaseItem,
  SortableList,
} from "pages/ProductDetails/components/SortableList";
import {
  createListItems,
  createManualSubject,
} from "pages/ProductDetails/tabs/thema/utils";
import { useSubjects } from "hooks/useSubjects";

import { FinalKeywordsEmpty } from "./FinalKeywordsEmpty";
import InputMode from "./InputMode";
import { CircleColorWarningCounterKeywordsCharactersCount } from "./CircleColorWarningCounterKeywordsCharactersCount";
import { googleAnalyticsKeywordSelectionReport } from "../../../../../../shared/services/KeywordService";

import { useKeywordsData } from "./useKeywordsData";

export const FinalKeywords = () => {
  const { t } = useTranslation(["productDetails"]);
  const { finalKeywords } = useKeywordsData();
  const [inputMode, setInputMode] = useState(false);

  const { gaEvent } = useAnalytics();
  const { product } = useProduct();
  const { handleChangeOrder, handleAddSubject, handleChangeManualSubject } =
    useSubjects();

  const finalKeywordsListItems = useMemo(
    () => createListItems(finalKeywords),
    [finalKeywords],
  );

  const hasKeywords = finalKeywords.length > 0;
  const finalKeywordsCharacters = finalKeywords.join("").trim().length;

  const finalKeywordsMap = useMemo(
    () => new Map(finalKeywords.map((subject) => [subject.id, subject])),
    [finalKeywords],
  );

  const onCopy = useCallback(async () => {
    gaEvent({
      type: "copy_keywords_from_final_selection",
      payload: googleAnalyticsKeywordSelectionReport(product),
    });

    await copyToClipboard({
      arrayToCopy: finalKeywords.map(
        (keyword) => keyword.subjectHeadingText ?? "",
      ),
    });

    toast.success(t("finalKeywords.toasts.copy.header"));
  }, [finalKeywords, t, gaEvent, product]);

  const handleChangeOrderWrapper = (items: BaseItem[]) => {
    if (product) {
      handleChangeOrder(items, product.id, "keyword");
    }
  };

  const onSaveKeywords = useCallback(
    async (keyword: string) => {
      try {
        const manualSubject = createManualSubject(keyword);
        await handleAddSubject(manualSubject);
      } catch (error) {
        toast.error(t("errorToast.errorUpdate"));
      }
    },
    [handleAddSubject, t],
  );

  const handleRemoveFinalSubjectsListItem = useCallback(
    (item: BaseItem | undefined) => {
      if (!item) return;

      const subjectItem = finalKeywordsMap.get(item.id);
      if (subjectItem) {
        handleAddSubject(subjectItem);
        gaEvent({
          type: "remove_keyword_from_final_selection",
          payload: {
            book: product?.id ?? "",
            keyword: subjectItem.subjectHeadingText ?? "",
          },
        });
      }
    },
    [finalKeywordsMap, handleAddSubject, gaEvent, product],
  );

  return (
    <div className="flex flex-col w-full max-w-80 border border-secondary-200 rounded-xl overflow-hidden h-full">
      <FinalHeader
        showAddButton
        inputMode={inputMode}
        setInputMode={setInputMode}
        label={t("finalKeywords.header")}
      />

      <div className="flex-1 flex flex-col min-h-0">
        {!inputMode && !hasKeywords ? (
          <FinalKeywordsEmpty />
        ) : (
          <div className="flex-1 overflow-y-auto">
            <SortableList
              items={finalKeywordsListItems}
              onChangeOrder={handleChangeOrderWrapper}
              onRemove={handleRemoveFinalSubjectsListItem}
              onEdit={handleChangeManualSubject}
            />

            {inputMode && (
              <InputMode
                product={product}
                saveFinalKeywords={onSaveKeywords}
                setInputMode={setInputMode}
              />
            )}
          </div>
        )}
      </div>

      <div className="flex justify-between border-t border-secondary-200 bg-background p-4">
        <Button
          size="small"
          variant="tertiary"
          icon="copy"
          label={t("finalKeywords.actions.copy")}
          disabled={!hasKeywords}
          onClick={onCopy}
        />

        <CircleColorWarningCounterKeywordsCharactersCount
          count={finalKeywordsCharacters || 0}
        />
      </div>
    </div>
  );
};
