import { useTranslation } from "react-i18next";
import { PageTitle } from "components/page/PageTitle";
import { PageSubtitle } from "components/page/PageSubtitle";
import { useOrganisations } from "contexts/OrganisationsProvider";
import { OrganisationsTable } from "./components/OrganisationsTable";
import { AddOrganisationModal } from "./components/AddOrganisationModal";

export const OrganisationsPage = () => {
  const { t } = useTranslation("settings");

  const { organisations } = useOrganisations();

  return (
    <div className="px-28 py-10 space-y-8 overflow-auto">
      <div className="space-y-1">
        <PageTitle>{t("settings:organisations.overview.title")}</PageTitle>
        <PageSubtitle>
          {t("settings:organisations.overview.subtitle.text", {
            count: organisations.length,
          })}
        </PageSubtitle>
      </div>

      <div className="space-y-4">
        <div className="flex justify-end">
          <AddOrganisationModal />
        </div>

        <OrganisationsTable organisations={organisations} />
      </div>
    </div>
  );
};
