import { useTranslation } from "react-i18next";

import { BaseListContent } from "components/thema/BaseListContent";
import { KeywordItem } from "../KeywordItem";
import { useKeywordsData } from "../FinalKeywords/useKeywordsData";

export const DatabaseKeywords = () => {
  const { t } = useTranslation(["general", "productDetails"]);

  const { finalKeywords, keywords } = useKeywordsData("database");

  return (
    <BaseListContent
      title={t("productDetails:databaseKeywords.header")}
      isEmptyList={!keywords.length}
      aria-label={t("productDetails:databaseKeywords.header")}
      emptyListProps={{
        title: t("productDetails:databaseKeywordsEmpty.title"),
        description: t("productDetails:databaseKeywordsEmpty.description"),
      }}
    >
      {keywords.map((item) => (
        <KeywordItem
          key={item.id}
          keyword={item}
          testId={`database-keyword-${item.id}`}
          isSelected={finalKeywords
            .map((keyword) => keyword.id)
            .includes(item.id)}
        />
      ))}
    </BaseListContent>
  );
};
