import * as React from "react";
import { useTranslation } from "react-i18next";
import { ContactUsForm } from "./ContactUsForm";
import { ContactUsSuccess } from "./ContactUsSuccess";
import { ContactUsError } from "./ContactUsError";

type FormStatus = "success" | "error" | "idle";

export function ContactUsContent() {
  const { t } = useTranslation(["contactUs"]);
  const [status, setStatus] = React.useState<FormStatus>("idle");

  if (status === "success") {
    return <ContactUsSuccess />;
  }

  if (status === "error") {
    return <ContactUsError />;
  }

  return (
    <div className="flex justify-center items-center pt-12">
      <div className="flex flex-col gap-6 self-center max-w-4xl">
        <p className="text-secondary-800 text-2xl font-semibold">
          {t("contactUs:header")}
        </p>
        <p className="text-secondary-600 text-m whitespace-pre-line">
          {t("contactUs:description")}
        </p>

        <ContactUsForm setFormStatus={setStatus} />
      </div>
    </div>
  );
}
