import { Badge } from "components/ui/badge";
import { Separator } from "components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { useTranslation } from "react-i18next";
import { Info } from "lucide-react";
import * as React from "react";
import { useCallback } from "react";
import { DetailsDropdown } from "./DetailsDropdown";
import { Button } from "../../../../components/button";
import { copyToClipboard } from "../../../../utils/helpers";
import { toast } from "../../../../utils/toast";

type DetailsInfoProps = {
  authors: string[];
  isbn: string;
  productGroupDescription: string;
};

export const DetailsInfo: React.FC<DetailsInfoProps> = ({
  authors,
  isbn,
  productGroupDescription,
}) => {
  const { t } = useTranslation(["productDetails"]);
  const [firstAuthor, ...otherAuthors] = authors;

  const handleIsbnCopyButtonClick = useCallback(async () => {
    await copyToClipboard({ arrayToCopy: [isbn] });
    toast.success(t("toasts.copyIsbn"));
  }, [isbn, t]);

  return (
    <div className="flex flex-row gap-2 w-full">
      <div className="flex items-center text-s font-medium">
        {firstAuthor || "-"}
        {otherAuthors?.length ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div
                  className="text-secondary-600 hover:text-secondary-800 p-1"
                  data-testid="authors-tooltip"
                >
                  <Info size={16} />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <span>
                  {otherAuthors.map((a) => (
                    <span key={a}>{a}</span>
                  ))}
                </span>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : null}
      </div>
      <Separator orientation="vertical" className="h-auto w-0.5" />
      <div className="flex flex-row items-center gap-1 group relative">
        <p className="text-s font-medium">{t("productSummary.isbn")}:</p>
        <p className="text-s">{isbn}</p>
        <div className="group-hover:flex hidden absolute right-0 left-0 bg-gradient-to-r justify-end from-transparent to-white">
          <Button
            variant="ghost"
            icon="copy"
            onClick={handleIsbnCopyButtonClick}
            iconPosition="right"
            aria-label={t("buttons.copyIsbn")}
            size="medium"
            className="pr-2 bg-white"
          />
        </div>
      </div>
      <Separator orientation="vertical" className="h-auto w-0.5" />
      <span className="flex flex-row items-center gap-1">
        <p className="text-s font-medium">{t("productSummary.form")}:</p>
        <Badge variant="secondary">{productGroupDescription}</Badge>
      </span>
      <DetailsDropdown />
    </div>
  );
};
