import { useTranslation } from "react-i18next";
import { Button } from "components/button";

interface SubmitButtonProps {
  isValid: boolean;
  submitLoading: boolean;
}

export const SubmitButton = ({ isValid, submitLoading }: SubmitButtonProps) => {
  const { t } = useTranslation(["contactUs"]);

  return (
    <div className="w-full flex justify-end">
      <Button
        label={submitLoading ? "Submitting..." : t("contactUs:form.submit")}
        type="submit"
        disabled={!isValid || submitLoading}
        size="small"
        testId="contact-us-submit"
      />
    </div>
  );
};
