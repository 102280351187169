import {
  Generation,
  getGeneratedCollection,
} from "integrations/firebase/collections/generationRequests";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import { useAuth } from "./AuthContext";

interface GenerationsContextValue {
  generations: Generation[];
  loading: boolean;
  error: Error | undefined;
}

const GenerationsContext = createContext<GenerationsContextValue>({
  generations: [],
  loading: false,
  error: undefined,
});

export const GenerationsProvider = ({ children }: PropsWithChildren) => {
  const { authUser } = useAuth();

  const [generations = [], loading, error] = useCollectionData(
    authUser
      ? query(getGeneratedCollection(), where("userId", "==", authUser.uid))
      : null,
  );

  const value = useMemo(
    () => ({ generations, loading, error }),
    [generations, loading, error],
  );

  return (
    <GenerationsContext.Provider value={value}>
      {children}
    </GenerationsContext.Provider>
  );
};

export const useGenerations = () => useContext(GenerationsContext);
