import { EditorContent, useEditor } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { cn } from "utils";
import { useEffect, useState } from "react";
import { useOrganisations } from "../../../../contexts/OrganisationsProvider";
import { logError } from "../../../../shared/services/ErrorReporting";
import { toast } from "../../../../utils/toast";

export const OrganisationName = ({
  id,
  name,
  isEditing,
  onChange,
}: {
  id: string;
  isEditing: boolean;
  name: string;
  onChange: () => void;
}) => {
  const { updateOrganisationName } = useOrganisations();
  const [loading, setLoading] = useState(false);

  const updateName = (value: string) => {
    setLoading(true);
    updateOrganisationName(id, value)
      .catch((error) => {
        logError(error.message);
        toast.error(error.message);
      })
      .then(() => setLoading(false));
  };

  const editor = useEditor({
    editorProps: { attributes: { role: "textbox", "aria-multiline": "true" } },
    onBlur: (ctx) => {
      const value = ctx.editor.getText().trim() || name;
      ctx.editor.commands.setContent(value);
      onChange();
      if (value !== name) {
        updateName(value);
      }
    },
    extensions: [
      Document,
      Paragraph.extend({
        addKeyboardShortcuts: () => ({
          Enter: (props) => props.editor.chain().blur().run(),
          Escape: (props) => {
            props.editor.commands.setContent(name);
            return props.editor.chain().blur().run();
          },
        }),
      }),
      Text,
    ],
    content: name,
    editable: isEditing || loading,
  });

  useEffect(() => {
    if (isEditing && !editor?.isFocused) {
      editor?.chain().focus("end").run();
    }
  }, [isEditing, editor]);

  useEffect(() => {
    if (editor?.getText() !== name) {
      editor?.commands.setContent(name);
    }
  }, [name, editor]);

  return (
    <div className="flex items-center">
      <EditorContent
        editor={editor}
        className={cn("transition", editor?.isFocused && "bg-primary-25")}
      />
    </div>
  );
};
