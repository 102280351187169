import { ColumnDef } from "@tanstack/react-table";

import { Checkbox } from "components/ui/checkbox";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { TFunction } from "i18next";

export const getColumns = (t: TFunction): ColumnDef<any>[] => [
  {
    id: "select",
    size: 0,
    header: ({ table }) => (
      <div>
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      </div>
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
  },
  {
    accessorKey: "term",
    size: NaN,
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("searchTerms:table.headers.name")}
      />
    ),
    cell: ({ row }) => (
      <div className="text-secondary-900">{row.getValue("term")}</div>
    ),
  },
  {
    accessorKey: "productsCount",
    size: NaN,
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("searchTerms:table.headers.books")}
      />
    ),
    cell: ({ row }) => (
      <span className="truncate">
        {`${row.getValue("productsCount")} Books`}
      </span>
    ),
  },
];
