import { Table } from "@tanstack/react-table";
import { IProduct } from "integrations/firebase/collections";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useErrorToast } from "hooks/useErrorToast";
import { toast } from "utils/toast";
import { LoaderIcon } from "lucide-react";
import { useProjectDetails } from "../contexts/ProjectDetailsContext";
import { BulkGenerateButton } from "./BulkGenerateButton";
import { ExportDropdown } from "./ExportDropdown";

interface PreHeaderProps {
  table: Table<IProduct>;
}

export const PreHeader = ({ table }: PreHeaderProps) => {
  const {
    generateKeywords,
    generateDescriptions,
    isLoadingGenerate,
    errorGenerate,
  } = useProjectDetails();
  const { t } = useTranslation(["projectDetails"]);
  const selectedProducts = table
    .getSelectedRowModel()
    .rows.map((row) => row.original);

  useErrorToast(
    errorGenerate ? t("projectDetails:toast.errorGenerate") : undefined,
  );

  useEffect(() => {
    if (isLoadingGenerate) {
      toast.success(t("projectDetails:toast.preGeneratingMetadata"), {
        icon: <LoaderIcon className="animate-spin duration-2000 w-5 h-5" />,
        id: "pre-generating-metadata-toast",
      });
    } else if (errorGenerate) {
      toast.dismiss("pre-generating-metadata-toast");
    }
  }, [isLoadingGenerate, errorGenerate, t]);

  return (
    <div className="flex flex-row w-full justify-end items-center">
      <div className="flex gap-2 items-center">
        {selectedProducts.length > 0 ? (
          <p className="text-secondary-500 ml-4 text-nowrap">
            {t("projectDetails:selected", { count: selectedProducts.length })}
          </p>
        ) : null}

        <BulkGenerateButton
          products={selectedProducts}
          generateKeywords={generateKeywords}
          generateDescriptions={generateDescriptions}
        />

        <ExportDropdown selectedProducts={selectedProducts} />
      </div>
    </div>
  );
};
