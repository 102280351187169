import { useCallback } from "react";
import { useProduct } from "contexts/ProductContext";
import { FirestoreProductSubject, SubjectSource } from "__generated__/models";
import { logError } from "shared/services/ErrorReporting";
import { BaseItem } from "pages/ProductDetails/components/SortableList";
import {
  filterSubjects,
  filterQualifiers,
  filterKeywords,
} from "pages/ProductDetails/tabs/thema/utils";
import { v4 } from "uuid";
import { useProductActions } from "./useProductActions";

export const useSubjects = () => {
  const { product } = useProduct();
  const { saveFinalSubjects } = useProductActions();

  const handleAddSubject = useCallback(
    async (subject: FirestoreProductSubject) => {
      if (!product) {
        logError("Product not found while adding subject");
        return;
      }
      if (subject) {
        const finalSubjects = product.subjects?.final || [];
        if (!finalSubjects.some((sub) => sub.id === subject.id)) {
          await saveFinalSubjects(product.id, [...finalSubjects, subject]);
        } else {
          await saveFinalSubjects(
            product.id,
            finalSubjects.filter((k) => k.id !== subject.id),
          );
        }
      }
    },
    [product, saveFinalSubjects],
  );

  const handleChangeManualSubject = useCallback(
    async (item: BaseItem, value: string) => {
      if (!product) {
        logError("Product not found while changing manual subject");
        return;
      }
      const allFinalItems = product.subjects?.final || [];
      let newId = item.id;
      const subjectToChangeIndex = allFinalItems.findIndex(
        (finalItem) => finalItem.id === item.id,
      );

      const subjectToChange = allFinalItems[subjectToChangeIndex];

      if (subjectToChange?.source !== SubjectSource.MANUAL) {
        newId = v4();
      }

      const newFinal = allFinalItems.map((finalItem, index) => {
        if (index === subjectToChangeIndex) {
          return {
            ...finalItem,
            subjectHeadingText: value,
            source: SubjectSource.MANUAL,
            rankingReasoning: null,
            ranking: null,
            relevance: null,
            id: newId,
          };
        }

        return finalItem;
      });

      await saveFinalSubjects(product?.id, newFinal);
    },
    [product, saveFinalSubjects],
  );

  const handleChangeOrder = useCallback(
    async (
      items: BaseItem[],
      productId: string,
      type: "subject" | "qualifier" | "keyword",
    ) => {
      const allFinalItems = product?.subjects?.final || [];
      const subjects = filterSubjects(allFinalItems);
      const qualifiers = filterQualifiers(allFinalItems);
      const keywords = filterKeywords(allFinalItems);
      let newOrder: FirestoreProductSubject[] = [];

      if (type === "subject") {
        const newSubjects = items
          .map((item) => subjects.find((sub) => sub.subjectCode === item.value))
          .filter(
            (item): item is FirestoreProductSubject => item !== undefined,
          );

        newOrder = [...newSubjects, ...qualifiers, ...keywords];
      } else if (type === "qualifier") {
        const newQualifiers = items
          .map((item) =>
            qualifiers.find((qual) => qual.subjectCode === item.value),
          )
          .filter(
            (item): item is FirestoreProductSubject => item !== undefined,
          );

        newOrder = [...subjects, ...newQualifiers, ...keywords];
      } else if (type === "keyword") {
        const newKeywords = items
          .map((item) => keywords.find((qual) => qual.id === item.id))
          .filter(
            (item): item is FirestoreProductSubject => item !== undefined,
          );

        newOrder = [...subjects, ...qualifiers, ...newKeywords];
      }

      if (newOrder.length === 0) {
        return;
      }

      await saveFinalSubjects(productId, newOrder);
    },
    [product?.subjects?.final, saveFinalSubjects],
  );

  return {
    handleAddSubject,
    handleChangeOrder,
    handleChangeManualSubject,
  };
};
