import type { Table as TTable } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { DataTablePagination } from "components/ui/data-table-pagination";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { IProduct } from "integrations/firebase/collections";
import { RoutePath } from "shared/constants";
import { SearchType } from "hooks/search/types";
import { useBacklist } from "contexts/BacklistContext";

import { PreHeader } from "./PreHeader";
import { BooksTableBody } from "./BooksTableBody";

interface BooksTableProps {
  table: TTable<IProduct>;
}

export const BooksTable = ({ table }: BooksTableProps) => {
  const { t } = useTranslation(["books"]);

  const { isLoading, searchQuery } = useBacklist();

  const navigate = useNavigate();

  const count = table.getRowCount();

  const selectAllButtonLabel = table.getSelectedAll()
    ? t("clearSelection")
    : t("selectAll", { count });

  const handleBookClick = useCallback(
    (product: IProduct) =>
      navigate(RoutePath.Book.replace(":idProduct", product.id)),
    [navigate],
  );

  return (
    <div className="space-y-4 text-left">
      <PreHeader table={table} />

      <Table className="">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody data-testid="books-table-body">
          <BooksTableBody
            columns={table.getAllColumns()}
            onRowClick={handleBookClick}
            loading={isLoading}
            table={table}
          />
        </TableBody>
      </Table>

      {searchQuery.type !== SearchType.SEMANTIC && (
        <DataTablePagination
          table={table}
          pageSizeLabel={t("pageSize")}
          selectAllLabel={count ? selectAllButtonLabel : undefined}
          initialPageIndex={1}
        />
      )}
    </div>
  );
};

BooksTable.displayName = "BooksTable";
