export enum TableColumns {
  Isbn = "ISBN",
  Author = "Author",
  Title = "Title",
  ProductForm = "Product form",
  Keywords = "Keywords Generated",
}

export enum TooltipKeys {
  keywordsGenerated = "keywordsGenerated",
  keywordsGenerationIsLoading = "keywordsGenerationIsLoading",
  keywordsGenerationFailed = "keywordsGenerationFailed",
  noKeywords = "noKeywords",
}

export enum OnixGenerationModalStatus {
  IDLE = "IDLE",
  REQUESTED = "REQUESTED",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}
