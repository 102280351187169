import { useMemo } from "react";
import { Row } from "@tanstack/react-table";
import { IProduct } from "integrations/firebase/collections";
import { getBadgeStatusFromRow } from "./utils";
import { GenerationStatusBadge } from "./GenerationStatusBadge";

interface TableStatusBadgesProps {
  row: Row<IProduct>;
}

export const TableStatusBadges = ({ row }: TableStatusBadgesProps) => {
  const { keywordsStatus, descriptionStatus, subjectsStatus } = useMemo(
    () => getBadgeStatusFromRow(row),
    [row],
  );

  const productId = row.original.id;

  return (
    <div className="flex items-center justify-center gap-x-1">
      <GenerationStatusBadge
        labelKey="keywords"
        productId={productId}
        status={keywordsStatus}
      />
      <GenerationStatusBadge
        labelKey="subjects"
        productId={productId}
        status={subjectsStatus}
      />
      <GenerationStatusBadge
        labelKey="description"
        productId={productId}
        status={descriptionStatus}
      />
    </div>
  );
};
