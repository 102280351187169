import { SubjectSchemeIdentifier } from "./types";

export const qualifierTypes = [
  SubjectSchemeIdentifier.ThemaPlaceQualifier,
  SubjectSchemeIdentifier.ThemaTimePeriodQualifier,
  SubjectSchemeIdentifier.ThemaStyleQualifier,
  SubjectSchemeIdentifier.ThemaEducationalPurposeQualifier,
  SubjectSchemeIdentifier.ThemaInterestAgeSpecialInterestQualifier,
];

export const subjectsTypes = [SubjectSchemeIdentifier.ThemaSubjectCategory];

export const keywordTypes = [SubjectSchemeIdentifier.Keyword];
