import { flexRender, Table } from "@tanstack/react-table";

import { TableCell, TableRow } from "components/ui/table";
import { useTranslation } from "react-i18next";
import { SkeletonRows } from "components/table/SkeletonRows";

interface SearchTermsTableBodyProps {
  table: Table<any>;
  isLoading: boolean;
}

export const SearchTermsTableBody = ({
  table,
  isLoading,
}: SearchTermsTableBodyProps) => {
  const { t } = useTranslation(["searchTerms"]);
  const columns = table.getAllColumns();

  if (isLoading) {
    return <SkeletonRows columns={columns} />;
  }

  const { rows } = table.getRowModel();

  if (!rows.length) {
    return (
      <TableRow>
        <TableCell colSpan={columns.length} className="h-24 text-center">
          {t("searchTerms:noResults")}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {rows.map((row) => (
        <TableRow
          key={row.id}
          data-state={row.getIsSelected() && "selected"}
          onClick={() => row.toggleSelected(!row.getIsSelected())}
        >
          {row.getVisibleCells().map((cell) => (
            <TableCell
              key={cell.id}
              style={{ width: cell.column.getSize() || undefined }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
