import { BacklistContextProvider } from "contexts/BacklistContext";
import { BooksPageInternal } from "./BooksPageInternal";

export const BooksPage = () => {
  return (
    <BacklistContextProvider>
      <BooksPageInternal />
    </BacklistContextProvider>
  );
};
