import { Client } from "@hey-api/client-fetch";
import {
  addOrganisationUsers,
  deleteOrganisationUser,
  postOrganisationFileRoute,
  getOrganisationFilesRoute,
  OrganisationUsersCreateRequest,
  patchUser,
  UserRole,
  getOrganisationFileReportRoute,
} from "__generated__/api";
import {
  addOrganisationUsersResponseSchema,
  deleteOrganisationUserResponseSchema,
  postOrganisationFileRouteResponseSchema,
  getOrganisationFilesRouteResponseSchema,
  patchUserResponseSchema,
  getOrganisationFileReportRouteResponseSchema,
} from "__generated__/api/validation.gen";
import { parseResult } from "../parseHelper";

export class OrganisationsApi {
  constructor(private client: Client) {}

  async getFiles(organisationId: string) {
    const response = await getOrganisationFilesRoute({
      path: { identifier: organisationId },
      client: this.client,
    });

    const result = parseResult(
      getOrganisationFilesRouteResponseSchema,
      response,
      {},
    );

    return result?.data;
  }

  async addFile(organisationId: string, file: File) {
    const response = await postOrganisationFileRoute({
      path: { identifier: organisationId, file_name: file.name },
      query: { no_sign: true },
      body: { upload: file },
      client: this.client,
    });

    const result = parseResult(
      postOrganisationFileRouteResponseSchema,
      response,
      {},
    );

    return result?.data;
  }

  async getReport(organisationId: string, fileName: string) {
    const response = await getOrganisationFileReportRoute({
      path: { identifier: organisationId, file_name: fileName },
      client: this.client,
    });

    const result = parseResult(
      getOrganisationFileReportRouteResponseSchema,
      response,
      {},
    );

    return result?.data;
  }

  async addUsers(organisationId: string, data: OrganisationUsersCreateRequest) {
    const response = await addOrganisationUsers({
      path: {
        identifier: organisationId,
      },
      body: data,
      client: this.client,
    });

    const result = parseResult(
      addOrganisationUsersResponseSchema,
      response,
      data,
    );

    return result?.data;
  }

  async removeUser(organisationId: string, userId: string) {
    const response = await deleteOrganisationUser({
      path: {
        identifier: organisationId,
        useridentifier: userId,
      },
      client: this.client,
    });

    const result = parseResult(deleteOrganisationUserResponseSchema, response, {
      organisationId,
      userId,
    });

    return result?.data;
  }

  async updateUserRole(organisationId: string, userId: string, role: UserRole) {
    const response = await patchUser({
      path: {
        identifier: organisationId,
        useridentifier: userId,
      },
      body: {
        roles: [role],
      },
      client: this.client,
    });

    const result = parseResult(patchUserResponseSchema, response, {
      organisationId,
      userId,
    });

    return result?.data;
  }
}
