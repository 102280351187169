import React, { Dispatch, SetStateAction } from "react";
import { IsbnMultipleSelectChip } from "components/select/IsbnMultipleSelectChip";
import ModalOverlayLoadingSpinner from "components/loading/ModalOverlayLoadingSpinner";
import { useTranslation } from "react-i18next";

type ProjectFormProps = {
  setSelectedIsbns: Dispatch<SetStateAction<string[]>>;
  loading: boolean;
  selectedIsbns: string[];
};

export const ProjectForm: React.FC<ProjectFormProps> = ({
  loading,
  selectedIsbns,
  setSelectedIsbns,
}) => {
  const { t } = useTranslation(["general", "projects"]);

  return (
    <>
      <ModalOverlayLoadingSpinner
        textTop={t("projects:isbnProjectForm.searching")}
        isLoading={loading}
      />
      <div className="h-full flex-1 flex items-center relative bg-white text-secondary-600">
        <IsbnMultipleSelectChip
          values={selectedIsbns}
          onValueChange={setSelectedIsbns}
          className="grid grid-cols-[repeat(auto-fill,_175px)] auto-rows-[35px] gap-y-1 gap-x-2 py-1 px-1"
          chipClassName="text-s"
          showErrors
        />
      </div>
    </>
  );
};
