import { ComponentProps, forwardRef } from "react";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { LucideX } from "lucide-react";
import { cn } from "../../utils";

interface IChipProps extends ComponentProps<"input"> {
  className?: string;
  inputClassName?: string;
  error?: string;

  onRemove(): void;
}

export const Chip = forwardRef<HTMLInputElement, IChipProps>(
  ({ error, onRemove, className, inputClassName, ...props }, ref) => {
    return (
      <div className={cn("rounded-full bg-secondary-50 flex", className)}>
        <TooltipProvider delayDuration={200}>
          <Tooltip open={error ? undefined : false}>
            <TooltipTrigger asChild>
              <input
                ref={ref}
                {...props}
                data-testid="multiple-select-chip-input"
                className={cn(
                  "flex-1 bg-transparent ring-0 outline-none w-32 h-full pl-4 py-2",
                  inputClassName,
                  error && "text-system-error",
                )}
              />
            </TooltipTrigger>
            <TooltipContent side="bottom" className="rounded-lg">
              <TooltipArrow className="fill-white shadow-md stroke-secondary-300" />
              {error}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <button
          type="button"
          className="w-9 flex items-center justify-center"
          onClick={onRemove}
        >
          <LucideX
            data-testid="remove-chip"
            className={cn("w-4 h-4", error && "text-system-error")}
          />
        </button>
      </div>
    );
  },
);

Chip.displayName = "Chip";
