import { TFunction } from "i18next";
import { FileText, ListTree, LoaderCircle, SquareStack } from "lucide-react";
import { FirestoreGeneratedStatus } from "__generated__/models";
import { Generation } from "integrations/firebase/collections";

export type LabelKey =
  | "keywords"
  | "description"
  | "subjects"
  | "generating"
  | "requested"
  | "error";

interface Label {
  key: LabelKey;
  label: string;
  icon: JSX.Element;
}

export const getTooltipLabel = (
  t: TFunction,
  label: string,
  status: FirestoreGeneratedStatus,
) => {
  switch (status) {
    case FirestoreGeneratedStatus.IMPORTED:
      return t("components:generationStatusBadge.tooltips.imported", {
        label,
      });
    case FirestoreGeneratedStatus.GENERATING:
      return t("components:generationStatusBadge.tooltips.generating", {
        label,
      });
    case FirestoreGeneratedStatus.COMPLETED:
      return t("components:generationStatusBadge.tooltips.completed", {
        label,
      });
    case FirestoreGeneratedStatus.ERROR:
      return t("components:generationStatusBadge.tooltips.error", {
        label,
      });
    case FirestoreGeneratedStatus.REQUESTED:
      return t("components:generationStatusBadge.tooltips.generating", {
        label,
      });
    default:
      return t("components:generationStatusBadge.tooltips.default", {
        label,
      });
  }
};
export const getLabels: (t: TFunction, color: string) => Label[] = (
  t,
  color,
) => [
  {
    key: "keywords",
    label: t("components:generationStatusBadge.labels.keywords"),
    icon: <SquareStack className="w-3 h-3" color={color} />,
  },
  {
    key: "description",
    label: t("components:generationStatusBadge.labels.description"),
    icon: <FileText className="w-3 h-3" color={color} />,
  },
  {
    key: "subjects",
    label: t("components:generationStatusBadge.labels.subjects"),
    icon: <ListTree className="w-3 h-3" color={color} />,
  },
  {
    key: "generating",
    label: t("components:generationStatusBadge.labels.generating"),
    icon: (
      <LoaderCircle
        data-testid="badge-generating-icon"
        className="w-3 h-3 animate-spin"
      />
    ),
  },
  {
    key: "requested",
    label: t("components:generationStatusBadge.labels.generating"),
    icon: (
      <LoaderCircle
        data-testid="badge-generating-icon"
        className="w-3 h-3 animate-spin"
      />
    ),
  },
];

export const getStatusLabel = (
  labelKey: "keywords" | "description" | "subjects" | "generating" | "error",
  status: FirestoreGeneratedStatus,
  generations: Generation[],
  productId?: string,
) => {
  if (labelKey === "subjects" && productId) {
    const isGeneratingSubjects = generations.some(
      (generation) =>
        (generation.status === "REQUESTED" ||
          generation.status === "GENERATING") &&
        generation.productId === productId,
    );
    if (isGeneratingSubjects) {
      return FirestoreGeneratedStatus.GENERATING;
    }
  }
  return status;
};

export const statusColors: Record<
  FirestoreGeneratedStatus,
  { bg: string; icon: string }
> = {
  [FirestoreGeneratedStatus.IMPORTED]: {
    bg: "bg-secondary-25",
    icon: "#A1A1AA", // bg-secondary-400
  },
  [FirestoreGeneratedStatus.COMPLETED]: {
    bg: "bg-primary-25",
    icon: "#2970FF", // bg-primary-500
  },
  [FirestoreGeneratedStatus.GENERATING]: {
    bg: "bg-primary-25",
    icon: "#2970FF", // bg-primary-500
  },
  [FirestoreGeneratedStatus.REQUESTED]: {
    bg: "bg-primary-25",
    icon: "#2970FF", // bg-primary-500
  },
  [FirestoreGeneratedStatus.ERROR]: {
    bg: "bg-icon-error-background",
    icon: "#DC2626", // bg-system-error
  },
};
