import { IsbnMultipleSelectChip } from "components/select/IsbnMultipleSelectChip";
import { useCallback, useMemo } from "react";

interface MultiTextInputProps {
  value?: string;

  onValueChange(value: string): void;
}

export const MultiTextInput = ({
  value,
  onValueChange,
}: MultiTextInputProps) => {
  const values = useMemo(
    () => value?.split(",").filter((v) => v) ?? [],
    [value],
  );

  const onChange = useCallback(
    (v: string[]) => {
      onValueChange(v.filter(Boolean).join(","));
    },
    [onValueChange],
  );

  return (
    <div className="h-full flex-1 flex items-center relative border-secondary-300 border border-l-0 rounded-r-lg shadow-sm bg-white text-secondary-600 pr-8">
      <IsbnMultipleSelectChip
        values={values}
        onValueChange={onChange}
        className="grid grid-cols-[repeat(auto-fill,_175px)] auto-rows-[35px] gap-y-1 gap-x-2 py-1 px-1"
        chipClassName="text-s"
      />
    </div>
  );
};
