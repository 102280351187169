import React from "react";
import { TabsContent } from "components/ui/tabs";

interface ProductDetailsTabBoxProps {
  value: string;
  children: React.ReactNode;
}

export const ProductDetailsTabBox: React.FC<ProductDetailsTabBoxProps> = ({
  value,
  children,
}) => {
  return (
    <TabsContent
      value={value}
      className="max-h-[var(--subject-list-container-height)] h-screen"
    >
      {children}
    </TabsContent>
  );
};
