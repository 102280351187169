import { FirestoreGenerationStatusCode } from "__generated__/api";
import { TFunction } from "i18next";

export function generationErrorMessage(
  t: TFunction,
  code?: FirestoreGenerationStatusCode | null,
) {
  switch (code) {
    // eslint-disable-next-line no-underscore-dangle
    case FirestoreGenerationStatusCode._402:
      return t(
        "productDetails:generation.errors.message.GENERATION_LIMIT_REACHED",
      );
    // eslint-disable-next-line no-underscore-dangle
    case FirestoreGenerationStatusCode._451:
      return t(
        "productDetails:generation.errors.message.GENERATION_SAFETY_ERROR",
      );
    default:
      return null;
  }
}
