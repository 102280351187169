import { cva, VariantProps } from "class-variance-authority";
import { cn } from "utils";

const buttonStyles = cva(
  "inline-flex items-center gap-x-2 font-medium whitespace-nowrap transition duration-300 rounded-lg",
  {
    variants: {
      variant: {
        primary:
          "bg-gradient-to-br from-cta-gradient-from to-cta-gradient-to hover:from-cta-hover-gradient-from hover:to-cta-hover-gradient-to border border-transparent text-white transition duration-300 ease-in-out relative z-10",
        secondary: "border border-primary text-primary-500 background",
        tertiary:
          "border border-secondary-200 bg-white text-secondary-600 shadow-sm",
        link: "border-transparent hover:text-primary-600",
        ghost: "border-transparent text-primary-600 hover:text-primary-800",
        destructive: "text-white bg-red-500 hover:bg-red-600 ",
        warning: "text-white bg-system-warning hover:bg-system-warning/90",
        danger: " text-white bg-system-error hover:bg-system-error/90",
      },
      size: {
        small: "py-2 px-4 text-xs",
        medium: "py-3 px-4 text-sm",
        large: "py-3 px-4 text-base",
      },
      disabled: {
        true: "opacity-50 pointer-events-none",
        false: "",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "medium",
    },
  },
);

export type ButtonStyleProps = VariantProps<typeof buttonStyles>;

export const getButtonClasses = ({
  variant,
  size,
  disabled,
  className,
}: ButtonStyleProps & { className?: string }) => {
  return cn(
    buttonStyles({
      variant,
      size,
      disabled,
    }),
    className,
  );
};
