import { Grid, GridHeader, GridTitle, GridContent } from "components/ui/grid";
import { BaseListContentProps } from "pages/ProductDetails/tabs/thema/types";
import { Button } from "components/button";
import { EmptyListVertical } from "components/EmptyList/EmptyListVertical";

export const BaseListContent = ({
  isEmptyList,
  emptyListProps,
  headerButtonProps,
  emptyComponent,
  title,
  children,
}: BaseListContentProps) => {
  return (
    <Grid>
      <GridHeader className="py-4">
        <GridTitle>{title}</GridTitle>
        {headerButtonProps && <Button {...headerButtonProps} />}
      </GridHeader>
      {isEmptyList ? (
        <EmptyListVertical
          {...emptyListProps}
          emptyComponent={emptyComponent}
          title={emptyListProps?.title || ""}
          description={emptyListProps?.description || ""}
        />
      ) : (
        <GridContent className="md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 overflow-y-auto">
          {children}
        </GridContent>
      )}
    </Grid>
  );
};
