export enum SmallCircleTypeColor {
  Ok = "#4EA52F",
  Warning = "#FFA700",
}

interface SmallCircleProps {
  type: SmallCircleTypeColor;
}

const SmallCircle = ({ type }: SmallCircleProps) => {
  return (
    <div className="w-2 h-2 rounded-full flex items-center mr-1">
      <div
        style={{ background: type, boxShadow: `0 0 5px ${type}` }}
        className="w-2 h-2 rounded-full"
        aria-label={type === SmallCircleTypeColor.Ok ? "ok" : "warning"}
        role="img"
      />
    </div>
  );
};

export default SmallCircle;
