import { useOrganisation } from "contexts/OrganisationProvider";
import { OrganisationMembersTable } from "./components/OrganisationMembersTable";
import { AddOrganisationMemberModal } from "./components/AddOrganisationMemberModal";

export const OrganisationMembersPage = () => {
  const { organisation, members } = useOrganisation();

  if (!organisation) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-end">
        <AddOrganisationMemberModal />
      </div>

      <OrganisationMembersTable members={members} />
    </div>
  );
};
