/* eslint-disable no-param-reassign */
import {
  makeStateUpdater,
  OnChangeFn,
  Row,
  RowData,
  Table,
  TableFeature,
  Updater,
} from "@tanstack/react-table";

type EditFeatureState = Record<string, boolean>;

export interface EditFeatureTableState {
  editing: EditFeatureState;
}

export interface EditFeatureOptions {
  onEditChange?: OnChangeFn<EditFeatureState>;
}

export interface EditFeatureRow {
  getIsEditing: () => boolean;
  toggleEditing: (editing: boolean) => void;
}

export interface EditFeatureInstance {
  setEditing: (updater: Updater<EditFeatureState>) => void;
}

export const EditFeature: TableFeature = {
  getInitialState: (state): EditFeatureTableState => ({
    editing: {},
    ...state,
  }),

  getDefaultOptions: <TData extends RowData>(
    table: Table<TData>,
  ): EditFeatureOptions => ({
    onEditChange: makeStateUpdater("editing", table),
  }),

  createTable: <TData extends RowData>(table: Table<TData>): void => {
    table.setEditing = (updater) => table.options.onEditChange?.(updater);
  },

  createRow: <TData extends RowData>(
    row: Row<TData>,
    table: Table<TData>,
  ): void => {
    row.getIsEditing = () => {
      const { editing } = table.getState();
      return editing[row.id] ?? false;
    };

    row.toggleEditing = (editing: boolean) => {
      const isEditing = row.getIsEditing();

      table.setEditing((old) => {
        if (isEditing === editing) {
          return old;
        }

        const state = { ...old };

        if (editing) {
          state[row.id] = true;
        } else {
          delete state[row.id];
        }

        return state;
      });
    };
  },
};

declare module "@tanstack/react-table" {
  interface TableState extends EditFeatureTableState {}

  // eslint-disable-next-line unused-imports/no-unused-vars
  interface TableOptionsResolved<TData extends RowData>
    extends EditFeatureOptions {}

  // eslint-disable-next-line unused-imports/no-unused-vars
  interface Table<TData extends RowData> extends EditFeatureInstance {}

  // eslint-disable-next-line unused-imports/no-unused-vars
  interface Row<TData extends RowData> extends EditFeatureRow {}
}
