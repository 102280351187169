import { useTranslation } from "react-i18next";
import { BaseListContent } from "components/thema/BaseListContent";
import { useDialog } from "hooks/useDialog";
import { KeywordItem } from "../KeywordItem";
import { useKeywordsData } from "../FinalKeywords/useKeywordsData";
import { RegenerateKeywordsDialog } from "./RegenerateKeywordsDialog";

interface GeneratedKeywordsProps {
  onGenerate: () => void;
}

export const GeneratedKeywords = ({ onGenerate }: GeneratedKeywordsProps) => {
  const { t } = useTranslation(["productDetails", "general"]);
  const { keywords, isEmptyList, finalKeywords } = useKeywordsData("generated");
  const { onOpenChange, isOpen, onOpen } = useDialog("regenerate-keywords");

  const removedDuplicateKeywords = keywords?.filter(
    (keyword, index, self) =>
      keyword.id && self.findIndex((k) => k.id === keyword.id) === index,
  );

  return (
    <>
      <BaseListContent
        title={t("productDetails:generatedKeywords.header")}
        isEmptyList={isEmptyList}
        headerButtonProps={
          !isEmptyList
            ? {
                onClick: onOpen,
                label: t("general:buttons.reGenerate"),
                icon: "loader",
                variant: "link",
                size: "small",
                className: "text-primary-500",
              }
            : undefined
        }
        emptyListProps={{
          label: t("general:buttons.generate"),
          icon: "loader",
          onClick: onGenerate,
          title: t("productDetails:noKeywords.title"),
          description: t("productDetails:noKeywords.description"),
        }}
      >
        {removedDuplicateKeywords?.map((item) => (
          <KeywordItem
            key={item.id}
            keyword={item}
            testId={`add-${item.id}-button`}
            isSelected={finalKeywords.map((k) => k.id).includes(item.id)}
          />
        ))}
      </BaseListContent>
      <RegenerateKeywordsDialog onOpenChange={onOpenChange} isOpen={isOpen} />
    </>
  );
};
