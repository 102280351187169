/* eslint-disable react/no-array-index-key,no-continue */
import { useMemo } from "react";
import { Table } from "@tanstack/react-table";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { DataTablePaginationPageSize } from "./data-table-pagination-page-size";

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  initialPageIndex?: number;
  pageSizeLabel?: string;
  selectAllLabel?: string;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onSetPage?: (index: number) => void;
}

interface Page {
  value: number;
  isEllipsis: boolean;
}

export function DataTablePagination<TData>({
  table,
  pageSizeLabel,
  selectAllLabel,
  onNextPage,
  onPreviousPage,
  onSetPage,
  initialPageIndex = 0,
}: DataTablePaginationProps<TData>) {
  const { t } = useTranslation("general");

  const onPrev = () => {
    if (onPreviousPage) {
      onPreviousPage();
    }
    table.previousPage();
  };

  const onNext = () => {
    if (onNextPage) {
      onNextPage();
    }

    table.nextPage();
  };

  const setTableIndex = (index: number) => {
    if (onSetPage) {
      onSetPage(index);
    }

    table.setPageIndex(index);
  };

  const { pageSize, pageIndex: currentPage } = table.getState().pagination;
  const pageCount = table.getPageCount();

  const pageNumbers = useMemo(() => {
    const pages: Page[] = [];
    const adjustedCurrentPage = currentPage - initialPageIndex;

    if (pageCount <= 3) {
      for (let i = 0; i < pageCount; i += 1) {
        pages.push({ value: i, isEllipsis: false });
      }
      return pages;
    }

    pages.push({ value: 0, isEllipsis: false });

    if (adjustedCurrentPage > 1) {
      pages.push({ value: -1, isEllipsis: true });
    }

    if (adjustedCurrentPage > 0 && adjustedCurrentPage < pageCount - 1) {
      pages.push({ value: adjustedCurrentPage, isEllipsis: false });
    }

    if (adjustedCurrentPage < pageCount - 2) {
      pages.push({ value: -1, isEllipsis: true });
    }

    pages.push({ value: pageCount - 1, isEllipsis: false });

    return pages;
  }, [pageCount, currentPage, initialPageIndex]);

  return (
    <div className="flex items-center justify-between px-2">
      <div className="flex items-center space-x-2">
        <Button
          variant="ghost"
          icon="chevron-left"
          className="text-secondary-500"
          onClick={onPrev}
          disabled={currentPage === initialPageIndex}
          label={t("buttons.prev")}
          testId="button-pagination-prev"
        />

        {pageNumbers.map((page, index) => {
          const key = `page-${page.isEllipsis ? "ellipsis" : page.value}-${index}`;
          const value = page.isEllipsis ? -1 : page.value + initialPageIndex;
          const displayValue = page.isEllipsis ? "..." : String(page.value + 1);

          return (
            <Button
              key={key}
              disabled={page.isEllipsis}
              variant="ghost"
              title={displayValue}
              className={`${
                currentPage === page.value + initialPageIndex
                  ? "bg-secondary-50"
                  : "bg-transparent"
              } text-secondary-500 h-8 w-8 p-0 rounded-md`}
              onClick={() => !page.isEllipsis && setTableIndex(value)}
              label={displayValue}
            />
          );
        })}
        <Button
          variant="ghost"
          className="text-secondary-500"
          icon="chevron-right"
          iconPosition="right"
          onClick={onNext}
          disabled={currentPage - initialPageIndex === pageCount - 1}
          label={t("buttons.next")}
          testId="button-pagination-next"
        />
        {selectAllLabel && (
          <button
            className="text-primary-500 underline"
            onClick={() => table.toggleSelectAll()}
            type="submit"
          >
            {selectAllLabel}
          </button>
        )}
      </div>
      {pageSizeLabel && (
        <DataTablePaginationPageSize
          label={pageSizeLabel}
          onSelect={(size) => table.setPageSize(size)}
          size={pageSize}
        />
      )}
    </div>
  );
}
