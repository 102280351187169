import {
  LDContext,
  useFlags,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import { useCallback, useEffect, useState } from "react";
import { Context, ContextKey, INITIAL_CONTEXT } from ".";
import { LaunchDarklyFeatureFlags, parseFeatureFlags } from "./utils";

export const useLaunchDarkly = () => {
  const client = useLDClient();
  const flags = useFlags<LaunchDarklyFeatureFlags>();

  const [contexts, setContexts] = useState<LDContext>(INITIAL_CONTEXT);

  useEffect(() => {
    if (!client) {
      return;
    }

    if (JSON.stringify(client.getContext()) === JSON.stringify(contexts)) {
      return;
    }

    client.identify(contexts).then();
  }, [contexts, client]);

  const features = parseFeatureFlags(flags);
  const setContext = useCallback(
    <K extends ContextKey, C extends Context[K]>(key: K, context: C) => {
      setContexts((current) => ({
        ...current,
        [key]: context,
      }));
    },
    [],
  );

  const clearContext = useCallback(() => {
    setContexts(INITIAL_CONTEXT);
  }, []);

  return {
    features,
    setContext,
    clearContext,
  };
};

export type FeatureFlags = ReturnType<typeof parseFeatureFlags>;
