import { useAuth } from "contexts/AuthContext";
import { RoutePath } from "shared/constants";
import { useTranslation } from "react-i18next";

import { NavigationBar } from "./NavigationBar";
import { Header } from "./Header";
import { UtilityMenu } from "./UtilityMenu";

interface AuthHeaderProps {
  displayNavigation?: boolean;
}

export function AuthHeader({ displayNavigation = true }: AuthHeaderProps) {
  const { t } = useTranslation(["general", "components"]);

  const { authUser } = useAuth();

  const navigationItems = [
    {
      label: t("components:authHeader.home"),
      path: RoutePath.Home,
    },
    { label: t("components:authHeader.books"), path: RoutePath.Books },

    {
      label: t("components:authHeader.searchTerms"),
      path: RoutePath.SearchTerms,
    },
    { label: t("components:authHeader.projects"), path: RoutePath.Projects },
  ];

  return (
    <Header>
      {authUser && displayNavigation && (
        <NavigationBar items={navigationItems} />
      )}
      <UtilityMenu />
    </Header>
  );
}
