import { TypesenseProduct } from "__generated__/api";
import { IProduct } from "integrations/firebase/collections";
import { timestampFromUnix } from "../../integrations/firebase/utils";

export const mapTypesenseToProduct = (
  typesenseProduct: TypesenseProduct,
): IProduct => {
  const { createdAt, updatedAt, publishedAt } = typesenseProduct;

  return {
    ...typesenseProduct,
    id: typesenseProduct.identifier,
    isbn: typesenseProduct.identifier,
    publishedAt: publishedAt ? timestampFromUnix(publishedAt) : null,
    createdAt: timestampFromUnix(createdAt),
    updatedAt: timestampFromUnix(updatedAt),
  };
};
