import {
  getStorage,
  connectStorageEmulator,
  ref,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { app } from "./app";

export { ref, getDownloadURL, uploadBytes, uploadBytesResumable };
export const storage = getStorage(
  app,
  `${import.meta.env.VITE_FIREBASE_PROJECT_ID}-firebase-storage`,
);

if (import.meta.env.VITE_USE_FIREBASE_EMULATOR === "true") {
  connectStorageEmulator(storage, "localhost", 9199);
}
