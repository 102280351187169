import { Client } from "@hey-api/client-fetch";
import { postEvaluation } from "__generated__/api";
import { postEvaluationResponseSchema } from "__generated__/api/validation.gen";
import { parseResult } from "../parseHelper";

export class EvaluationApi {
  constructor(private client: Client) {}

  async evaluate(isbns: string[]) {
    const response = await postEvaluation({
      body: { requestedProducts: isbns },
      client: this.client,
    });

    const result = parseResult(postEvaluationResponseSchema, response, {});

    return result?.data;
  }
}
