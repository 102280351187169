import React, { memo, useCallback, useState } from "react";
import { Button } from "components/button";
import { Modal } from "components/modal/Modal";
import { useApi } from "hooks/useApi";
import { useDialog } from "hooks/useDialog";
import { useTranslation } from "react-i18next";
import { toast } from "utils/toast";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { useAuth } from "contexts/AuthContext";
import { UserRole } from "__generated__/models";
import { EvaluationProgressModal } from "./EvaluationProgressModal";

interface EvaluationModalProps {
  selectedProducts: string[];
}

export const EvaluationModal = memo(
  ({ selectedProducts }: EvaluationModalProps) => {
    const api = useApi();
    const { userData } = useAuth();
    const { features } = useFeatureFlags();
    const { t } = useTranslation(["books"]);
    const [evaluationId, setEvaluationId] = useState<string>();
    const warning = useDialog("evaluation-warning");
    const [requestingEvaluation, setRequestingEvaluation] = useState(false);

    const onRunEvaluation = useCallback(async () => {
      try {
        if (requestingEvaluation) {
          return;
        }

        setRequestingEvaluation(true);

        const response = await api.evaluation.evaluate(selectedProducts);

        warning.onClose();

        if (!response) {
          throw new Error("An unknown error occurred");
        }

        setEvaluationId(response.id);
      } catch (e) {
        toast.error(`Failed to run evaluation: ${(e as Error).message}`);
      } finally {
        setRequestingEvaluation(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      selectedProducts,
      requestingEvaluation,
      warning.onClose,
      api.evaluation,
    ]);

    if (
      !features.evaluation ||
      !userData?.roles?.includes(UserRole.SUPER_ADMIN)
    ) {
      return null;
    }

    return (
      <>
        {evaluationId && (
          <EvaluationProgressModal
            evaluationId={evaluationId}
            onConfirm={() => setEvaluationId(undefined)}
            onClose={() => setEvaluationId(undefined)}
          />
        )}

        <Modal
          id={warning.id}
          open={warning.isOpen && !evaluationId}
          onOpenChange={warning.onOpenChange}
          title={t("books:evaluation.modal.warning.title")}
          description={t("books:evaluation.modal.warning.description")}
          confirmLabel={t("books:evaluation.modal.warning.actions.confirm")}
          cancelLabel={t("books:evaluation.modal.warning.actions.cancel")}
          variant="warning"
          onConfirm={onRunEvaluation}
          trigger={
            <Button
              variant="tertiary"
              disabled={requestingEvaluation || !selectedProducts.length}
              icon="chart-pie"
            >
              {t("books:evaluation.evaluate")}
            </Button>
          }
        />
      </>
    );
  },
);

EvaluationModal.displayName = "EvaluationModal";
