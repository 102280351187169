import { cva, VariantProps } from "class-variance-authority";
import { Icon, IconProps, IconSizes } from "../icon/Icon";
import { cn } from "../../utils";

interface ModalIconProps extends VariantProps<typeof modalIconClasses> {
  className?: string;
  iconClassName?: string;
  iconSize?: IconSizes;
  pulse?: boolean;
  icon: IconProps["name"];
}

const modalIconClasses = cva("rounded-full flex justify-center items-center", {
  variants: {
    pulse: {
      true: "p-4",
      false: "ring-4 ring-opacity-5",
    },
    variant: {
      primary: "bg-primary-500 text-primary-500",
      warning: "bg-system-warning text-system-warning",
      danger: "bg-system-error text-system-error",
    },
  },
  defaultVariants: {
    pulse: true,
    variant: "primary",
  },
});

export const ModalIcon = ({
  className,
  iconClassName,
  icon,
  iconSize = "large",
  pulse = true,
  variant = "primary",
}: ModalIconProps) => {
  return (
    <div
      className={cn(
        modalIconClasses({ pulse, variant }),
        className,
        "bg-opacity-10 relative",
      )}
    >
      {pulse && (
        <div
          className={cn(
            "absolute inset-0 bg-opacity-25 rounded-full animate-ping duration-2000",
            modalIconClasses({ variant }),
          )}
        />
      )}

      <Icon
        className="absolute"
        name={icon}
        size={iconSize}
        iconClassName={cn(
          `h-full w-full text-${variant} relative`,
          iconClassName,
        )}
      />
    </div>
  );
};
