import { useTranslation } from "react-i18next";
import { Input } from "components/ui/input";
import { PopoverClose } from "components/ui/popover";

import { Button } from "components/button";
import {
  SearchFilterComparisonOperator,
  SearchQueryFilter,
  SearchQueryFilterSchema,
} from "hooks/search/types";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ComparisonOperatorDropdown } from "./ComparisonOperatorDropdown";

interface NumericFilterProps {
  value?: SearchQueryFilter | null;

  onValueChange(value: SearchQueryFilter | null): void;
}

export const NumericFilter = ({ value, onValueChange }: NumericFilterProps) => {
  const { t } = useTranslation(["books"]);

  const { handleSubmit, control, register, reset } = useForm({
    defaultValues: {
      comparisonOperator:
        value?.comparisonOperator ?? SearchFilterComparisonOperator.GREATER,
      value: value?.value ?? 0,
    },
    resolver: zodResolver(SearchQueryFilterSchema),
  });

  const clearFilter = () => {
    onValueChange(null);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onValueChange)}>
      <span className="w-full flex flex-row">
        <Controller
          control={control}
          name="comparisonOperator"
          render={({ field }) => (
            <ComparisonOperatorDropdown
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <Input
          {...register("value", { valueAsNumber: true })}
          type="number"
          min={0}
          className="border-l-0 rounded-l-none text-sm text-center"
          data-testid="keyword-filter-input"
        />
      </span>

      <PopoverClose className="w-full mt-2" asChild>
        <span className="flex flex-row w-full justify-end gap-2">
          <Button
            variant="tertiary"
            size="small"
            label={t("books:filter.clear")}
            onClick={clearFilter}
            analyticsId="button_clear_filter"
          />
          <Button
            size="small"
            label={t("books:filter.apply")}
            type="submit"
            analyticsId="button_apply_filter"
          />
        </span>
      </PopoverClose>
    </form>
  );
};
