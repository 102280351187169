import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useBacklist } from "contexts/BacklistContext";
import { BooksTable } from "./table/BooksTable";
import { columns } from "./table/columns";
import { useTable } from "../../hooks/useTable";
import { BooksPageHeader } from "./components/BooksPageHeader";
import { SearchType } from "../../hooks/search/types";

export const BooksPageInternal = memo(() => {
  const { t } = useTranslation(["books"]);

  const {
    data: { products, count },
    pagination,
    setPagination,
    isLoading,
    searchQuery,
  } = useBacklist();

  const tableColumns = useMemo(
    () =>
      columns(t, {
        loading: isLoading,
        showMatch:
          searchQuery.type === SearchType.SEMANTIC && !!searchQuery.query,
      }),
    [t, isLoading, searchQuery.type, searchQuery.query],
  );

  const { table } = useTable({
    columns: tableColumns,
    data: products,
    enableRowSelection: true,
    totalRecords: count,
    paginationState: {
      pagination,
      setPagination,
    },
  });

  return (
    <div className="space-y-8">
      <BooksPageHeader table={table} />
      <BooksTable table={table} />
    </div>
  );
});

BooksPageInternal.displayName = "BooksPageInternal";
