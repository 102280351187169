import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import LoadingSpinner from "components/loading/LoadingSpinner";
import React from "react";
import { IProduct } from "../../../../../../integrations/firebase/collections";
import { Badge } from "../../../../../../components/ui/badge";

interface OrganisationOnixUploadFileReportDialogTableProps {
  loading?: boolean;
  products: Array<
    Partial<Omit<IProduct, "publishedAt">> & {
      failed?: boolean;
      publishedAt?: Date | null;
    }
  >;
}

export const OrganisationOnixUploadFileReportDialogTable = ({
  loading,
  products,
}: OrganisationOnixUploadFileReportDialogTableProps) => {
  const { t } = useTranslation(["settings"]);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("settings:organisation.onix.table.isbn")}</TableHead>
          <TableHead>{t("settings:organisation.onix.table.author")}</TableHead>
          <TableHead>{t("settings:organisation.onix.table.title")}</TableHead>
          <TableHead>
            {t("settings:organisation.onix.table.productForm")}
          </TableHead>
          <TableHead>
            {t("settings:organisation.onix.table.publishedOn")}
          </TableHead>
          <TableHead>
            {t("settings:organisation.onix.table.qualityCheck")}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell>
              <div className="flex items-center justify-center w-full pt-10">
                <LoadingSpinner size="100px" />
              </div>
            </TableCell>
          </TableRow>
        ) : (
          products.map((product) => (
            <TableRow key={product.isbn}>
              <TableCell>{product.isbn}</TableCell>
              <TableCell>{product.author}</TableCell>
              <TableCell>{product.title}</TableCell>
              <TableCell>{product.formatDescription}</TableCell>
              <TableCell>
                {product.publishedAt
                  ? format(product.publishedAt, "dd/MM/yyyy")
                  : ""}
              </TableCell>
              <TableCell>
                {product.failed ? (
                  <Badge variant="error" className="ml-2">
                    {t("settings:organisation.onix.misc.badge.failed")}
                  </Badge>
                ) : (
                  <Badge variant="success" className="ml-2">
                    {t("settings:organisation.onix.misc.badge.success")}
                  </Badge>
                )}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};
