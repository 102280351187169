import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useDebounce } from "hooks/useDebounce";
import { useDeepCompareMemo } from "use-deep-compare";
import { useApi } from "../useApi";
import { SearchQuery } from "./types";
import { searchQueryToSearchRequest } from "./utils";

export const useSearch = (
  query: Partial<SearchQuery>,
  options?: {
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
    debounce?: number;
    onSearch?(): void;
  },
) => {
  const api = useApi();

  const request = useDeepCompareMemo(
    () => searchQueryToSearchRequest(query),
    [query],
  );

  const params = useDebounce(request, options?.debounce ?? 100);

  const result = useQuery({
    queryKey: ["search", params] as const,
    queryFn: ({ queryKey: [, p] }) => {
      options?.onSearch?.();

      return api.search(p);
    },
    refetchOnWindowFocus: options?.refetchOnWindowFocus || false,
    enabled: options?.enabled,
    retry: false,
    placeholderData: keepPreviousData,
  });

  return {
    ...result,
    requestParams: request,
  };
};
