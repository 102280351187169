import { DropdownMenuItem } from "components/ui/dropdown-menu";

export interface ExportOptionProps {
  label: string;
  disabled?: boolean;

  onClick: () => void;
}

export function ExportOption({ disabled, onClick, label }: ExportOptionProps) {
  return (
    <DropdownMenuItem
      className="py-2.5 px-3 cursor-pointer"
      disabled={disabled}
      onClick={onClick}
      role="menuitem"
      aria-label={label}
    >
      <span className="text-s">{label}</span>
    </DropdownMenuItem>
  );
}
