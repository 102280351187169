import { useMemo } from "react";
import { useProduct } from "contexts/ProductContext";
import { filterKeywords } from "pages/ProductDetails/tabs/thema/utils";
import { IProduct } from "integrations/firebase/collections/products";

export type KeywordsListVariant = "generated" | "database";

type SubjectType = "database" | "generated" | "final";

const useMemoizedKeywords = (
  subjects: IProduct["subjects"],
  type: SubjectType,
) => useMemo(() => filterKeywords(subjects?.[type] ?? []), [subjects, type]);

export const useKeywordsData = (variant?: KeywordsListVariant) => {
  const { product } = useProduct();

  const databaseKeywords = useMemoizedKeywords(product?.subjects, "database");
  const generatedKeywords = useMemoizedKeywords(product?.subjects, "generated");
  const finalKeywords = useMemoizedKeywords(product?.subjects, "final");

  const keywords =
    variant === "database" ? databaseKeywords : generatedKeywords;
  const isEmptyList = keywords.length === 0;

  return { keywords, finalKeywords, generatedKeywords, isEmptyList };
};
