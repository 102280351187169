import React, { useCallback, useEffect } from "react";
import { cn } from "utils";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { FileRejection, useDropzone } from "react-dropzone";
import { Input } from "components/ui/input";
import { useTranslation } from "react-i18next";
import { AvatarFallback } from "@radix-ui/react-avatar";
import { toast } from "utils/toast";
import AddImageIcon from "assets/svg/addUserAvatar.svg?react";
import { Plus } from "lucide-react";
import { Avatar, AvatarImage } from "../ui/avatar";

interface ProfilePictureUploadProps {
  className?: string;
  photoUrl?: string | null;
  onUpdate: (file: File) => void;
}

export const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({
  className,
  onUpdate,
  photoUrl,
}) => {
  const { t } = useTranslation("profile");
  const [preview, setPreview] = React.useState<string | ArrayBuffer | null>("");
  const [dropError, setDropError] = React.useState<string | null>(null);

  const getErrorMessage = useCallback(
    (fileRejections: readonly FileRejection[]) => {
      const errorCode = fileRejections[0].errors[0].code;
      switch (errorCode) {
        case "file-invalid-type":
          return t("profile:setup.form.photoUrl.error.invalidType");
        case "file-too-large":
          return t("profile:setup.form.photoUrl.error.tooLarge");
        case "too-many-files":
          return t("profile:setup.form.photoUrl.error.tooManyFiles");
        default:
          return t("profile:setup.form.photoUrl.error.unknown");
      }
    },
    [t],
  );

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      const reader = new FileReader();
      try {
        reader.onload = () => setPreview(reader.result);
        reader.readAsDataURL(acceptedFiles[0]);
        onUpdate(acceptedFiles[0]);
      } catch (error) {
        setPreview(null);
      }
    },
    [onUpdate],
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      maxFiles: 1,
      maxSize: 2500000,
      accept: { "image/png": [], "image/jpg": [], "image/jpeg": [] },
    });

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (fileRejections.length !== 0) {
      setDropError(getErrorMessage(fileRejections));
    }
  }, [fileRejections, getErrorMessage]);

  useEffect(() => {
    if (dropError)
      toast.error(dropError, { onDismiss: () => setDropError(null) });
  }, [dropError]);

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger onClick={handleClick}>
          <div {...getRootProps()}>
            <Avatar
              className={cn(
                className,
                "mx-auto h-32 w-32 relative group transition-all",
                isDragActive && "border-2 border-grey-500",
              )}
            >
              {preview && <AvatarImage src={preview as string} />}
              {!preview && photoUrl && <AvatarImage src={photoUrl} />}
              {!preview && !photoUrl && (
                <AvatarFallback className="uppercase font-semibold">
                  <AddImageIcon />
                </AvatarFallback>
              )}
              <div className="absolute inset-0 bg-gray-700/10 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                <Plus className="w-8 h-8 text-black" />
              </div>
            </Avatar>

            <Input
              {...getInputProps()}
              type="file"
              data-testid="photo-upload-input"
            />
          </div>
        </TooltipTrigger>
        <TooltipContent side="bottom" sideOffset={-24}>
          <TooltipArrow className="fill-white shadow-md stroke-secondary-300" />
          {photoUrl
            ? t("profile:setup.form.photoUrl.tooltip.change")
            : t("profile:setup.form.photoUrl.tooltip.add")}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
