import { TFunction } from "i18next";
import { TicketCategory } from "integrations/zendesk";
import { z } from "zod";

export const signUpSchema = (t: TFunction) =>
  z.object({
    email: z.string().email(),
    name: z.string(),
    category: z.nativeEnum(TicketCategory),
    isbn: z
      .string()
      .optional()
      .refine((isbn) => isbn?.length === 0 || isbn?.length === 13, {
        message: t("contactUs:form.errors.isbn"),
      }),
    message: z.string().max(2000).min(1),
  });

export type SignUpSchemaType = z.infer<ReturnType<typeof signUpSchema>>;

export const categoryTranslation = (t: TFunction) => ({
  [TicketCategory.question]: t("contactUs:form.category.select.question"),
  [TicketCategory.access_issue]: t(
    "contactUs:form.category.select.accessIssues",
  ),
  [TicketCategory.feature_request]: t(
    "contactUs:form.category.select.featureRequest",
  ),
  [TicketCategory.bug]: t("contactUs:form.category.select.bug"),
  [TicketCategory.feedback]: t("contactUs:form.category.select.feedback"),
  [TicketCategory.other]: t("contactUs:form.category.select.other"),
});
