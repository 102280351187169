import { useTranslation } from "react-i18next";
import { useProduct } from "contexts/ProductContext";
import { GenerationStatus } from "integrations/firebase/collections";
import { FinalKeywords } from "./components/FinalKeywords/FinalKeywords";
import { GeneratedKeywords } from "./components/GeneratedKeywords/GeneratedKeywords";
import { DatabaseKeywords } from "./components/DatabaseKeywords";
import { KeywordsGenerating } from "./KeywordsGenerating";
import { KeywordsError } from "./KeywordsError";
import { logError } from "../../../../shared/services/ErrorReporting";
import { toast } from "../../../../utils/toast";
import { useProductActions } from "../../../../hooks/useProductActions";

export const KeywordsTab = () => {
  const { product } = useProduct();
  const { generateProductKeywords } = useProductActions();
  const { t } = useTranslation(["productDetails"]);

  if (!product) {
    return;
  }

  const generatedKeywords = product?.generated?.keywords;

  const isGenerating = generatedKeywords?.status === GenerationStatus.REQUESTED;
  const isError = generatedKeywords?.status === GenerationStatus.ERROR;

  const handleGenerate = async () => {
    try {
      await generateProductKeywords(product);
    } catch (e) {
      logError(e);
      toast.error(
        t("productDetails:generatedKeywords.errorToast.errorGenerate"),
      );
    }
  };

  return (
    <div className="flex flex-row gap-4 h-full">
      <DatabaseKeywords />
      {isGenerating ? <KeywordsGenerating /> : null}
      {isError ? (
        <KeywordsError
          onGenerate={handleGenerate}
          statusCode={generatedKeywords?.statusCode}
        />
      ) : null}
      {!isError && !isGenerating ? (
        <GeneratedKeywords onGenerate={handleGenerate} />
      ) : null}
      <FinalKeywords />
    </div>
  );
};
