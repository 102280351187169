import { collection, QueryDocumentSnapshot } from "firebase/firestore";

import { db } from "integrations/firebase/firestore";
import { FirestoreUser, FirestoreOrganisation } from "__generated__/models";

interface IUserSettings {
  visitedSearchTermsPage?: boolean;
  acceptedTermsVersion?: string;
}

export interface IUser extends FirestoreUser {
  familyName: string;
  givenName: string;
  photoUrl?: string;
  organisation: FirestoreOrganisation;
  settings?: IUserSettings;
}

const userConverter = {
  toFirestore: (user: IUser) => user,
  fromFirestore(snapshot: QueryDocumentSnapshot<IUser>): IUser {
    const data = snapshot.data();

    return {
      ...data,
      id: snapshot.id,
    };
  },
};

export const getUsersCollection = () =>
  collection(db, "users").withConverter(userConverter);
