import { useAuth } from "contexts/AuthContext";
import { useMemo } from "react";
import { api } from "../api/client";

export function useApi() {
  const { authToken } = useAuth();

  return useMemo(() => {
    api.setAuthToken(authToken);

    return api;
  }, [authToken]);
}
