import { ColumnDef } from "@tanstack/react-table";
import { TableCell, TableRow } from "components/ui/table";
import { IProduct } from "integrations/firebase/collections";
import { memo } from "react";
import { createArray, getRandomNumber } from "utils/helpers";
import { Skeleton } from "../../../components/ui/skeleton";

interface SkeletonRowsProps {
  columns: ColumnDef<IProduct>[];
}

export const SkeletonRows = memo(({ columns }: SkeletonRowsProps) => {
  return (
    <>
      {createArray(10).map((i) => (
        <TableRow key={i.toString()}>
          {columns.map((column) => {
            const key =
              "accessorKey" in column ? column.accessorKey : column.id;

            let width: number;

            switch (key) {
              case undefined:
              case "select":
                width = 0;
                break;
              case "isbn":
                width = 7;
                break;
              case "publishedAt":
                width = 10;
                break;
              case "title":
                width = getRandomNumber(10, 20);
                break;
              case "author":
                width = getRandomNumber(5, 10);
                break;
              case "productGroupDescription":
                width = getRandomNumber(5, 10);
                break;
              default:
                width = 5;
                break;
            }

            return (
              <TableCell key={key}>
                <Skeleton
                  style={{ width: `${width}rem` }}
                  className="h-[24px]"
                />
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </>
  );
});

SkeletonRows.displayName = "SkeletonRows";
