import { useCallback, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

import { IProduct, watchProducts } from "integrations/firebase/collections";
import { logError } from "shared/services/ErrorReporting";
import { FirestoreError } from "firebase/firestore";
import { useAuth } from "contexts/AuthContext";
import { useOrganisation } from "./useOrganisation";

export const useProducts = (isbns: undefined | string[]) => {
  const [products, setProducts] = useState<IProduct[]>();

  const [isLoadingRead, setIsLoadingRead] = useState(true);

  const [errorRead, setErrorRead] = useState<FirestoreError>();

  const organisation = useOrganisation();
  const { userData } = useAuth();

  const subscribeProducts = useCallback(
    (isbnsArray: string[]) => {
      setProducts([]);
      setErrorRead(undefined);
      if (isbnsArray.length === 0) {
        setIsLoadingRead(false);
        return;
      }
      setIsLoadingRead(true);

      if (!organisation) {
        throw new Error(`No organisation found for user: ${userData?.email}`);
      }

      const handleNext = (newProducts: IProduct[]) => {
        const nonExistingIsbns = isbnsArray
          .filter(
            (identifier) =>
              !newProducts?.find((op) => op.identifier === identifier),
          )
          .map((i) => {
            return {
              isbn: i,
              title: "",
              nonExistent: true,
            } as IProduct;
          });

        setProducts([...newProducts, ...nonExistingIsbns]);
        setIsLoadingRead(false);
      };

      const handleError = (err: FirestoreError) => {
        setProducts(
          (currentProducts: undefined | IProduct[]) => currentProducts ?? [],
        );
        logError(err);
        setErrorRead(err as FirestoreError);
        setIsLoadingRead(false);
      };

      const unsubscribe = watchProducts(
        isbnsArray,
        organisation.id,
        handleNext,
        handleError,
      );

      return () => unsubscribe;
    },
    [organisation, userData?.email],
  );

  useDeepCompareEffect(() => {
    if (!isbns || isbns.length === 0) {
      setIsLoadingRead(false);
      return;
    }

    const unsubscribe = subscribeProducts(isbns);
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [subscribeProducts, isbns]);

  return {
    isLoadingRead,
    errorRead,
    products,
  };
};
