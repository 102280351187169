import React from "react";
import { Button } from "components/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { useTranslation } from "react-i18next";
import { ModalIcon } from "components/modal/ModalIcon";
import { useForm } from "react-hook-form";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { useDialog } from "hooks/useDialog";
import { toast } from "utils/toast";
import { useOrganisations } from "contexts/OrganisationsProvider";

interface FormValues {
  name: string;
}

export const AddOrganisationModal = () => {
  const { t } = useTranslation(["settings"]);

  const { addOrganisation } = useOrganisations();

  const dialog = useDialog("add-organisation");

  const form = useForm<FormValues>({
    defaultValues: {
      name: "",
    },
  });

  async function onCreate(values: FormValues) {
    try {
      await addOrganisation({ name: values.name });

      toast.success(
        t("settings:organisations.overview.add.success", {
          name: values.name,
        }),
      );

      form.reset();
      dialog.close();
    } catch (error) {
      toast.error(
        t("settings:organisations.overview.add.error", {
          message: (error as Error).message,
        }),
      );
    }
  }

  const onSubmit = form.handleSubmit(onCreate);

  return (
    <Dialog open={dialog.isOpen} onOpenChange={dialog.onOpenChange}>
      <DialogTrigger asChild>
        <Button
          label={t("settings:organisations.overview.add.button")}
          icon="plus"
          className="py-2 font-normal"
        />
      </DialogTrigger>

      <DialogContent className="overflow-visible">
        <DialogHeader className="mb-6">
          <ModalIcon
            icon="building"
            className="bg-primary-100 bg-opacity-75 mb-4 mt-4 ring-8 ring-opacity-5 ring-primary-700"
            pulse={false}
            iconClassName="text-primary-700"
          />

          <DialogTitle className="">
            {t("settings:organisations.overview.add.modal.title")}
          </DialogTitle>
          <DialogDescription className="">
            {t("settings:organisations.overview.add.modal.description")}
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={onSubmit}>
          <Label className="flex flex-col justify-center space-y-3">
            <div>
              {t("settings:organisations.overview.add.modal.name.label")}
            </div>
            <Input
              className="placeholder:text-secondary-400 rounded-lg placeholder:text-s text-s"
              {...form.register("name", {
                required: true,
                setValueAs: (v) => v.trim(),
              })}
            />
          </Label>
        </form>

        <DialogFixedFooter className="flex justify-between gap-2 px-5 items-center">
          <div className="flex justify-end gap-2 ml-auto">
            <DialogClose asChild>
              <Button
                label={t(
                  "settings:organisations.overview.add.modal.actions.cancel",
                )}
                variant="tertiary"
                size="small"
              />
            </DialogClose>
            <Button
              onClick={() => onSubmit()}
              label={t("settings:organisations.overview.add.modal.actions.add")}
              size="small"
              icon="plus"
            />
          </div>
        </DialogFixedFooter>
      </DialogContent>
    </Dialog>
  );
};
