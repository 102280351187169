import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { GripVertical } from "lucide-react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "components/button";
import { Input } from "components/ui/input";
import { cn } from "utils";
import { SortableItemInfo } from "./SortableItemInfo";

type SortableItemProps = {
  index: number;
  id: string;
  value: string;
  description?: string;
  infoText?: string;
  onRemove: () => void;
  onChange?: (newVal: string) => void;
};

export const SortableItem: FC<SortableItemProps> = ({
  value,
  id,
  index,
  description,
  infoText,
  onRemove,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);
  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id, disabled: isEditing });

  const handleInputKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter" || !onChange) {
        return;
      }

      e.preventDefault();
      setIsEditing(false);

      if (newValue && value !== newValue) {
        onChange(newValue);
      }
    },
    [onChange, newValue, value],
  );

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  }, []);

  const handleValueClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onBlur = useCallback(() => {
    if (!onChange) return;

    setIsEditing(false);

    if (newValue && value !== newValue) {
      onChange(newValue);
    }
  }, [newValue, value, onChange]);

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "flex w-full items-center justify-between bg-transparent group",
        isDragging && "opacity-40",
      )}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <div
        className="flex flex-row w-full px-4 py-4 items-center"
        {...attributes}
      >
        <div
          {...listeners}
          ref={setActivatorNodeRef}
          className="h-5 min-w-6 flex items-center cursor-grab"
        >
          <GripVertical
            className={cn(
              "hidden h-4 w-4 -ml-1",
              !isEditing && "group-hover:block",
            )}
          />
          <p
            className={cn(
              "block text-s text-secondary-400",
              !isEditing && "group-hover:hidden",
            )}
          >
            {index + 1}.
          </p>
        </div>

        {isEditing ? (
          <Input
            ref={inputRef}
            value={newValue}
            onKeyDown={handleInputKeyDown}
            onChange={handleInputChange}
            onBlur={onBlur}
            autoFocus
            className="text-s"
          />
        ) : (
          <button
            type="button"
            disabled={!onChange}
            className="text-s flex flex-row flex-grow text-left cursor-pointer overflow-hidden"
            onClick={handleValueClick}
          >
            <SortableItemInfo
              value={value}
              description={description}
              infoText={infoText}
            />
          </button>
        )}

        <div className="flex items-center">
          {!isEditing && (
            <Button
              label=""
              variant="ghost"
              disabled={isEditing}
              icon="x"
              onClick={onRemove}
              className="p-0 hidden group-hover:block"
              analyticsId="button_remove_final_keyword"
              testId={`remove-${id}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
