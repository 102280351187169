import { SkeletonOnix } from "components/skeletons/SkeletonOnix";

export const GeneratingSkeleton = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col gap-4 flex-grow-0 relative">
        <SkeletonOnix />
        <SkeletonOnix primary />
        <SkeletonOnix />
      </div>
    </div>
  );
};
