import { Button } from "components/button";
import { SkeletonKeyword } from "components/skeletons/SkeletonKeyword";
import { Icon, IconProps } from "components/icon/Icon";
import { isValidElement, useMemo } from "react";
import { cn } from "utils/cn";
import { EmptyListProps } from "./EmptyListTypes";

export const EmptyListVertical: React.FC<EmptyListProps> = ({
  onClick,
  title,
  description,
  label,
  icon,
  errorMessage,
  emptyComponent,
  titleStyle,
}) => {
  const showButton = label || icon;
  const iconElement = useMemo(() => {
    if (typeof icon === "string") {
      return <Icon name={icon as IconProps["name"]} />;
    }
    if (isValidElement(icon)) {
      return icon;
    }
    return null;
  }, [icon]);

  return (
    <div className="relative h-full w-full items-center self-center flex flex-col justify-center gap-2 my-2">
      {emptyComponent || (
        <div className="flex justify-center flex-col">
          <SkeletonKeyword
            animated={false}
            className="scale-75 relative animate-none opacity-60"
          />
          <SkeletonKeyword
            animated={false}
            primary
            className="scale-75 relative animate-none"
          />
          <SkeletonKeyword
            animated={false}
            className="scale-75 relative animate-none opacity-60"
          />
        </div>
      )}
      <div className="flex flex-col my-4 gap-2 items-center text-center max-w-64">
        <p
          className={cn(
            "font-medium text-m text-left text-secondary-900",
            titleStyle,
          )}
        >
          {title}
        </p>
        <p className="text-s font-normal text-secondary-500">{description}</p>
        {errorMessage && (
          <p className="text-s font-normal text-red-400 pb-4">{errorMessage}</p>
        )}

        {showButton && (
          <Button
            label={
              <div className="flex flex-row items-center gap-2">
                {iconElement}
                <p className="text-s">{label}</p>
              </div>
            }
            onClick={onClick}
            size="small"
            analyticsId="button_regenerate_keywords"
          />
        )}
      </div>
    </div>
  );
};
