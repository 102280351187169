import { memo } from "react";
import { SearchQuery } from "hooks/search/types";
import { Control, useController } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useTranslation } from "react-i18next";

interface SearchResultsCountDropdownProps {
  control: Control<SearchQuery>;
}

const options = [10, 20, 50, 100, 150, 250] as const;

export const SearchResultsCountDropdown = memo(
  ({ control }: SearchResultsCountDropdownProps) => {
    const { field } = useController({
      name: "pageSize",
      control,
    });

    const { t } = useTranslation(["books"]);

    return (
      <div className="flex items-center space-x-3">
        <Select
          value={String(field.value ?? 10)}
          onValueChange={field.onChange}
        >
          <SelectTrigger
            className="h-full border-secondary-300 w-20 space-x-2 rounded-lg"
            data-testid="search-results-count-dropdown"
          >
            <SelectValue />
          </SelectTrigger>
          <SelectContent className="min-w-[5rem]">
            <SelectGroup>
              {options.map((option) => (
                <SelectItem
                  key={option}
                  value={String(option)}
                  data-testid={`search-results-count-item-${option}`}
                >
                  {option}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>

        <div className="text-secondary-500 text-sm">
          {t("books:search.resultsCount")}
        </div>
      </div>
    );
  },
);

SearchResultsCountDropdown.displayName = "SearchResultsCountDropdown";
