import { ColumnDef, flexRender, Table } from "@tanstack/react-table";
import { TableCell, TableRow } from "components/ui/table";
import { IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { SkeletonRows } from "./SkeletonRows";

interface BooksTableBodyProps {
  table: Table<IProduct>;
  onRowClick: (product: IProduct) => void;
  columns: ColumnDef<IProduct>[];
  loading: boolean;
}

export const BooksTableBody = ({
  table,
  columns,
  loading,
  onRowClick,
}: BooksTableBodyProps) => {
  const { t } = useTranslation("projects");

  const onClick = useCallback(
    (product: IProduct, index: number) => {
      if (index === 0 || product.nonExistent) {
        return;
      }

      onRowClick(product);
    },
    [onRowClick],
  );

  if (loading) {
    return <SkeletonRows columns={columns} />;
  }

  if (table.getRowModel().rows?.length) {
    return (
      <>
        {table.getRowModel().rows.map((row) => (
          <TableRow
            key={row.id}
            data-testid={`book-${row.id}`}
            data-book-id={row.id}
            data-state={row.getIsSelected() && "selected"}
          >
            {row.getVisibleCells().map((cell, index) => (
              <TableCell
                id={cell.id}
                onClick={() => onClick(row.original, index)}
                key={cell.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  }

  return (
    <TableRow>
      <TableCell colSpan={columns.length} className="h-24 text-center">
        {t("books:booktable.noResults")}
      </TableCell>
    </TableRow>
  );
};

BooksTableBody.displayName = "BooksTableBody";
