import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Button } from "components/button";
import { OrganisationOnixUploadFileUploadDropZone } from "./OrganisationOnixUploadFileUploadDropZone";

export const OrganisationOnixUploadFileUploadDialog = ({
  open,
  onOpenChange,
  onFileUpload,
}: {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onFileUpload: (acceptedFiles: File[]) => void;
}) => {
  const { t } = useTranslation(["settings"]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button
          label={t("settings:organisation.onix.fileUpload.button")}
          className="py-2 font-normal"
        />
      </DialogTrigger>
      <DialogContent className="overflow-visible">
        <DialogHeader className="items-start space-y-0 my-0 mb-8">
          <DialogTitle>
            {t("settings:organisation.onix.fileUpload.dialog.title")}
          </DialogTitle>
          <DialogDescription className="text-left">
            {t("settings:organisation.onix.fileUpload.dialog.description")}
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-5">
          <OrganisationOnixUploadFileUploadDropZone onDrop={onFileUpload} />
        </div>

        <DialogFixedFooter className="flex justify-between gap-2 px-5 items-center">
          <div className="flex justify-end gap-2 ml-auto">
            <DialogClose asChild>
              <Button
                label={t("settings:organisation.onix.fileUpload.dialog.cancel")}
                variant="tertiary"
                size="small"
              />
            </DialogClose>
            <Button
              label={t("settings:organisation.onix.fileUpload.dialog.upload")}
              size="small"
            />
          </div>
        </DialogFixedFooter>
      </DialogContent>
    </Dialog>
  );
};
