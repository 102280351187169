import { useCallback } from "react";
import { Icon } from "components/icon/Icon";
import { useSubjects } from "hooks/useSubjects";
import { FirestoreProductSubject } from "__generated__/models";
import { cn } from "utils";

interface SubjectItemProps {
  subject: FirestoreProductSubject;
  isSelected: boolean;
  testId: string;
  isLast?: boolean;
}

export const SubjectItem = ({
  subject,
  isSelected,
  testId,
  isLast = false,
}: SubjectItemProps) => {
  const { handleAddSubject } = useSubjects();

  const handleClicked = useCallback(() => {
    handleAddSubject(subject);
  }, [handleAddSubject, subject]);

  return (
    <button
      type="button"
      className={cn(
        "flex flex-row w-full justify-between items-center p-4 text-left text-s",
        !isLast && "border-b",
      )}
      onClick={handleClicked}
      data-testid={testId}
    >
      <div className="flex flex-row gap-x-2 w-full overflow-hidden pr-2">
        <p className="text-s text-secondary-900">{subject?.subjectCode}</p>
        <p className="text-s text-secondary-400 truncate">
          {subject?.subjectHeadingText}
        </p>
      </div>

      <span
        className={cn(
          "shrink-0",
          isSelected ? "text-primary-500" : "text-secondary-400",
        )}
      >
        <Icon
          name={isSelected ? "circle-check" : "circle-plus"}
          size="medium"
          data-testid={
            isSelected
              ? `check-icon-${subject.subjectCode}`
              : `add-icon-${subject.subjectCode}`
          }
        />
      </span>
    </button>
  );
};
