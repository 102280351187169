import { Skeleton } from "components/ui/skeleton";
import { cn } from "utils";

interface SkeletonOnixProps {
  primary?: boolean;
  className?: string;
  animated?: boolean;
}

export const SkeletonOnix = ({
  primary = false,
  className,
  animated = true,
}: SkeletonOnixProps) => {
  return (
    <div
      className={cn(
        `relative transition-colors border-[1.15px] rounded-md p-3 bg-white ${primary ? "border-primary-300 shadow-[0px_6px_12px_0px_#528BFF14]" : "border-secondary-200"} flex items-center space-x-6 flex-grow-0`,
        className,
      )}
    >
      <Skeleton className="h-4 w-4 bg-secondary-100 rounded-full" />
      <Skeleton animated={animated} className="h-4 w-40 bg-secondary-100" />
      <Skeleton animated={animated} className="h-4 w-40 bg-secondary-100" />
    </div>
  );
};
