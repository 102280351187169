import { DropdownMenuItem } from "components/ui/dropdown-menu";
import { Loader } from "lucide-react";

export interface BulkGenerateOptionProps {
  label: string;
  disabled?: boolean;

  onClick: () => void;
}

export function BulkGenerateOption({
  disabled,
  onClick,
  label,
}: BulkGenerateOptionProps) {
  return (
    <DropdownMenuItem
      className="py-2.5 px-3 cursor-pointer"
      disabled={disabled}
      onClick={onClick}
    >
      <Loader className="w-5 h-5 mr-2" />
      <span className="text-s">{label}</span>
    </DropdownMenuItem>
  );
}
