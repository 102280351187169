import { Icon } from "components/icon/Icon";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipArrow,
} from "components/ui/tooltip";

interface SortableItemInfoProps {
  description?: string;
  infoText?: string;
  value?: string;
}

export const SortableItemInfo = ({
  description,
  infoText,
  value,
}: SortableItemInfoProps) => {
  return (
    <div className="flex items-center group">
      <p className="text-secondary-800 mr-2 truncate">{value}</p>

      {infoText && (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="group-hover:block hidden">
              <Icon color="#A1A1AA" name="info" />
            </span>
          </TooltipTrigger>
          <TooltipContent
            side="bottom"
            className="text-left shadow-tooltip text-neutral-600 whitespace-normal z-10 border-none leading-relaxed max-w-xs"
            sideOffset={6}
          >
            <TooltipArrow
              className="fill-white drop-shadow-sm"
              width={12}
              height={6}
            />
            <p className="text-s">{infoText}</p>
          </TooltipContent>
        </Tooltip>
      )}
      <span className="text-secondary-400 ml-2 truncate">{description}</span>
    </div>
  );
};
