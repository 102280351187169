import { z } from "zod";
import { IssueData } from "zod/lib/ZodError";

export function createSchema(length: string, unique: string) {
  const value = z.string().regex(/^\d{13}$/, length);

  const schema = z.object({
    values: z.array(z.object({ value })),
  });

  return schema.superRefine((data, context) => {
    const items = data.values.map((v) => v.value).filter(Boolean);
    const seen = new Set<string>();

    items.forEach((item, index) => {
      if (!seen.has(item)) {
        seen.add(item);
        return;
      }

      context.addIssue({
        path: [`values.${index}.value`],
        message: unique,
      } as IssueData);
    });
  });
}
